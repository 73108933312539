import { Text } from "./Text";

export interface FieldI {
    type: string;
    listType?: string;
    table?: string;
    fields?: Array<FieldI>;
    inline?: boolean;
    list?: boolean;
    min?: number;
    max?: number;
    name: string;
    description: string | unknown;
    hint: string | unknown;
    required: boolean;
    default: unknown;
    none: boolean;
    public: boolean;
    publicDescription: string | unknown;
    fatal: boolean;
    noDataMessage: string | unknown;
    preview: boolean;
    maxLength: number;
    unalterable: boolean;
    listPreview: string;
    listPreviewText: string | unknown;
    new: boolean;
}

export interface Config {
    use: {
        fields: Array<FieldI>;
        confirm: boolean;
        submitText: string | unknown;
        history: {
            hint: string | unknown;
        };
    };
    setup: {
        fields: Array<FieldI>;
        onchange: string
    };
}

export default interface CommandI {
    nameEN: string;
    name: string | Text;
    link: string;
    description: string | Text;
    aliases: Array<string>;
    category: string | Text;
    categoryEN: string;
    permissions?: number | string;
    authorized: boolean;
    table?: string;
    config?: Config | Text;
}

export const Categories: { [key: string]: string } = {
    administration: "#a91d1d",
    moderation: "#a9991d",
    communication: "#a91da8"
};
