import React = require("react");
import CommandI, { FieldI } from "../../types/Command";
import UserGuildI from "../../types/UserGuild";
import { UIField, UIFieldProps } from "./Field";

export interface ColorFieldTexts {
    noAccess: string | unknown;
}

export interface ColorFieldProps extends UIFieldProps {
    command: CommandI;
    guild: UserGuildI;
    field: FieldI;
    value?: string;
}

export interface ColorFieldState {
    color: string;
}

export default class ColorField extends React.Component<ColorFieldProps, ColorFieldState> implements UIField {

    private static DEFAULT_VALUE = "#304B9C";

    constructor(props: ColorFieldProps) {
        super(props);
        this.state = {
            color: this.props.value || (this.props.field.default as string) || ColorField.DEFAULT_VALUE
        };
    }

    render(): React.ReactNode {
        const { field } = this.props;
        return (
            <label className="custom-input-color">
                <span className="custom-input-color-span" style={{ backgroundColor: this.state.color }}></span>
                <input className="custom-input-color-input" type="color" defaultValue={this.state.color} required={field.required} name={field.name} onChange={this.changeColor.bind(this)} />
            </label>
        );
    }

    changeColor(event: React.ChangeEvent): void {
        const value: string = (event.currentTarget as HTMLInputElement).value;
        this.setState({
            color: value
        });
        if (this.checkValue()) this.triggerOnChange(value);
    }

    triggerOnChange(value: string): void {
        if (this.props.onChange) {
            this.props.onChange({
                name: this.props.field.name,
                value: value
            });
        }
    }

    checkValue(): boolean {
        return true;
    }

    public getValue(): string {
        return this.state.color;
    }

    public displayError(): void {
        //Impossible
    }

}
