import React = require("react");
import { FooterTexts } from "../partials/Footer";
import { HeaderTexts } from "../partials/Header";
import SetupMenu, { SetupMenuTexts } from "../partials/SetupMenu";
import CommandI from "../types/Command";
import UserI from "../types/User";
import UserGuildI from "../types/UserGuild";
import TemplateMain from "./TemplateMain";

export interface SetupProps {
    dark: boolean;
    texts: {
        header: HeaderTexts;
        footer: FooterTexts;
        setupMenu: SetupMenuTexts;
    };
    user?: UserI;
    page: string;
    language: string;
    path: string;
    guild: UserGuildI;
    command?: CommandI;
}

export default class TemplateSetup extends React.Component<SetupProps, Record<string, unknown>> {

    render(): React.ReactNode {
        const { texts, children, guild, command } = this.props;
        return (
            <TemplateMain {...this.props}>
                    <div className="d-flex w-100">
                        <SetupMenu texts={texts.setupMenu} guild={guild} command={command} />
                        <div className="container">{children}</div>
                    </div>
            </TemplateMain>
        );
    }

}
