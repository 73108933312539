import React from "react";
import ReactDOM from "react-dom";

type ModalType = { modal: boolean; modalContent: React.ReactElement; modalOptions: ModalOptions; showModal: (content: React.ReactElement, options?: ModalOptions) => void; hideModal: () => void, dismiss: () => void };
type ModalOptions = {
    appearence?: "success" | "danger" | "none";
    dismissable?: "none" | "button" | "all";
    onDismiss?: () => void;
};

let ModalContext: React.Context<ModalType>;
const { Provider } = (ModalContext = React.createContext(undefined));

export const useModal = (): ModalType => React.useContext(ModalContext);

const useModalLocal = (): ModalType => {
    const modalDefaultOptions: ModalOptions = { appearence: "success", dismissable: "none" };

    const [modal, setModal] = React.useState(false);
    const [modalContent, setModalContent] = React.useState<React.ReactElement>(undefined);
    const [modalOptions, setModalOptions] = React.useState<ModalOptions>(modalDefaultOptions);

    const showModal = (content: React.ReactElement, options?: ModalOptions) => {
        setModal(true);
        setModalContent(content);
        setModalOptions(Object.assign(Object.assign({}, modalDefaultOptions), options));
    };

    const hideModal = () => {
        setModal(false);
    };

    const dismiss = () => {
        hideModal();
        if (modalOptions.onDismiss) modalOptions.onDismiss();
    };

    return { modal, modalContent, modalOptions, showModal, hideModal, dismiss };
};

export const Modal = (): React.ReactElement => {
    const { modalContent, modalOptions, modal, dismiss } = useModal();
    if (typeof window === "undefined") return null;
    return ReactDOM.createPortal(
        <div className={`modal-background ${modal ? "modal-active" : ""}`} onClick={modalOptions.dismissable === "all" ? dismiss : undefined}>
            <div className={`modal modal-${modalOptions.appearence}`}>
                <div className="modal-appearence">
                    {modalOptions.appearence === "success" && (
                        <svg aria-hidden="true" height="16" width="12" viewBox="0 0 12 16">
                            <path fillRule="evenodd" d="M12 5.5l-8 8-4-4L1.5 8 4 10.5 10.5 4 12 5.5z"></path>
                        </svg>
                    )}
                    {modalOptions.appearence === "danger" && (
                        <svg aria-hidden="true" height="16" width="16" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M8.893 1.5c-.183-.31-.52-.5-.887-.5s-.703.19-.886.5L.138 13.499a.98.98 0 0 0 0 1.001c.193.31.53.501.886.501h13.964c.367 0 .704-.19.877-.5a1.03 1.03 0 0 0 .01-1.002L8.893 1.5zm.133 11.497H6.987v-2.003h2.039v2.003zm0-3.004H6.987V5.987h2.039v4.006z"></path>
                        </svg>
                    )}
                </div>
                <div className="modal-body">{modalContent}</div>
                {modalOptions.dismissable !== "none" && (
                    <span className="modal-dismiss" onClick={dismiss}>
                        <svg aria-hidden="true" height="16" width="14" viewBox="0 0 14 16">
                            <path fillRule="evenodd" d="M7.71 8.23l3.75 3.75-1.48 1.48-3.75-3.75-3.75 3.75L1 11.98l3.75-3.75L1 4.48 2.48 3l3.75 3.75L9.98 3l1.48 1.48-3.75 3.75z"></path>
                        </svg>
                    </span>
                )}
            </div>
        </div>,
        document.body
    );
};

export const ModalElement = {
    Title: ({ children }: { children: React.ReactNode }): React.ReactElement => <div className="modal-body-title">{children}</div>,
    Description: ({ children }: { children: React.ReactNode }): React.ReactElement => <div className="modal-body-description">{children}</div>,
    Buttons: ({ children }: { children: React.ReactNode }): React.ReactElement => <div className="modal-body-buttons">{children}</div>
};

export const ModalProvider = ({ children }: { children: React.ReactElement }): React.ReactElement => {
    const { modal, modalContent, modalOptions, showModal, hideModal, dismiss } = useModalLocal();
    return (
        <Provider value={{ modal, modalContent, modalOptions, showModal, hideModal, dismiss }}>
            <Modal />
            {children}
        </Provider>
    );
};
