import React = require("react");

interface ComponentProps {
    page: string;
}

export default class Head extends React.Component<ComponentProps, Record<string, unknown>> {

    render(): React.ReactNode {
        const page = this.props.page;

        return (
            <head>
                <meta charSet="UTF-8" />
                <meta name="tags" content="Discord,Xebot,Xarlion,XartEz,Discord Bot,Bot Multifonctions,Bot Xebot,Xebot Discord,Bot Discord Multifonctions{{#if tags}},{{tags}}{{/if}}" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no" />
                {/* <!-- HTML Meta Tags --> */}
                <title>{`Xebot - ${page}`}</title>
                <meta name="title" content="Xebot - The Discord Bot" />
                <meta name="description" content="Xebot est un bot multifonctions pour Discord" />
                <link rel="icon" href="/assets/images/icons/site_icon.png" />

                {/* <!-- Google / Search Engine Tags --> */}
                <meta itemProp="name" content="Xebot - The Discord Bot" />
                <meta itemProp="description" content="Xebot est un bot multifonctions pour Discord" />
                <meta itemProp="image" content="/assets/images/logos/xebot.png" />

                {/* <!-- Facebook Meta Tags --> */}
                <meta property="og:site_name" content="Xebot" />
                <meta property="og:url" content="https://xebot.fr" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Xebot - The Discord Bot" />
                <meta property="og:description" content="Xebot est un bot multifonctions pour Discord" />
                <meta property="og:image" content="/assets/images/logos/xebot.png" />

                {/* <!-- Twitter Meta Tags --> */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Xebot - The Discord Bot" />
                <meta name="twitter:description" content="Xebot est un bot multifonctions pour Discord" />
                <meta name="twitter:image" content="/assets/images/logos/xebot.png" />

                <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css" integrity="sha384-JcKb8q3iqJ61gNV9KGb8thSsNjpSL0n8PARn9HuZOnIxN0hoP+VmmDGMN5t9UJ0Z" crossOrigin="anonymous" />
                <link rel="stylesheet" href="/assets/css/dark-mode.min.css" />
                <link rel="stylesheet" href="/assets/css/style.css" />
                <link rel="stylesheet" href="https://unpkg.com/aos@next/dist/aos.css" />
                <link rel="stylesheet" href="/assets/css/Draft.css" />

                <script defer src="https://code.jquery.com/jquery-3.5.1.slim.min.js" integrity="sha384-DfXdz2htPH0lsSSs5nCTpuj/zy4C+OGpamoFVy38MVBnE+IbbVYUew+OrCXaRkfj" crossOrigin="anonymous"></script>
                <script defer src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/js/bootstrap.bundle.min.js" integrity="sha384-Piv4xVNRyMGpqkS2by6br4gNJ7DXjqk09RmUpJ8jgGtD7zP9yug3goQfGII0yAns" crossOrigin="anonymous"></script>
                <script defer src="https://unpkg.com/aos@next/dist/aos.js"></script>
                <script defer src="https://kit.fontawesome.com/baa1c53e1a.js" crossOrigin="anonymous"></script>
                <script defer src="/assets/js/main.js"></script>
            </head>
        );
    }

}
