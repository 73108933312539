import React = require("react");

export interface StayInScreenProps {
    className: string;
}

export interface StayInScreenState {
    offScreen: number;
}

export default class StayInScreen extends React.Component<StayInScreenProps, StayInScreenState> {

    element: React.RefObject<HTMLDivElement> = React.createRef();

    constructor(props: StayInScreenProps) {
        super(props);
        this.state = {
            offScreen: 0
        };
    }

    render(): React.ReactNode {
        const { children } = this.props;
        return (
            <div ref={this.element} {...this.props} style={{ marginLeft: this.state.offScreen }}>
                {children}
            </div>
        );
    }

    componentDidMount(): void {
        const div = this.element.current;
        const pos = div.getBoundingClientRect();
        const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        const offScreen = vw - pos.right;
        this.setState({
            offScreen: offScreen < 0 ? offScreen : 0
        });
    }

}
