import React = require("react");

export interface ScrollToProps {
    element: string;
    direction?: "up" | "down";
    className?: string;
    id?: string;
}

export default class ScrollTo extends React.Component<ScrollToProps> {

    render(): React.ReactNode {
        const { id, element, direction, className } = this.props;
        return (
            <a id={id} href={`#${element}`} rel="nofollow" className={`scroll-to ${className}`}>
                <span
                    style={{
                        display: "block",
                        height: "2rem",
                        width: "2rem",
                        borderBottom: "4px solid",
                        borderRight: "4px solid",
                        borderColor: "var(--xebot-dark-color-secondary)",
                        borderRadius: "5px",
                        transform: `rotate(${direction === "up" ? "225" : "45"}deg)`
                    }}
                ></span>
            </a>
        );
    }

}
