import React, { useEffect, useRef, useState } from "react";
import AnimateHeight from "react-animate-height";
import { useToasts } from "react-toast-notifications";
import superagent = require("superagent");
import CheckBoxField from "../partials/fields/CheckBox";
import Field, { FieldTexts } from "../partials/fields/Field";
import { FooterTexts } from "../partials/Footer";
import { HeaderTexts } from "../partials/Header";
import MessageHistory, { MessageHistoryTexts } from "../partials/MessageHistory";
import { SetupMenuTexts } from "../partials/SetupMenu";
import TemplateSetup from "../templates/TemplateSetup";
import UseCommandI, { Categories, Config } from "../types/Command";
import UserI from "../types/User";
import UserGuildI from "../types/UserGuild";
import EmojiPicker from "../utils/EmojiPicker";
import { ModalElement, useModal } from "../utils/Modal";
import TabContainer, { Tab, TabContent, TabData, Tabs, TabsContent } from "../utils/Tabs";

export interface UseCommandTexts {
    noAccess: string | unknown;
    requiredField: string | unknown;
    unknown: string | unknown;
    submit: {
        edit: string | unknown;
        create: string | unknown;
        setup: {
            submit: string | unknown;
            success: string | unknown;
            error: string | unknown;
        };
    };
    edit: {
        unalterable: string | unknown;
    };
    tabs: {
        use: string | unknown;
        setup: string | unknown;
    };
    completeMode: string | unknown;
    compactMode: string | unknown;
    modals: {
        succesWithMessage: {
            description: string | unknown;
            edit: string | unknown;
        };
        success: {
            title: string | unknown;
        };
        error: {
            title: string | unknown;
            p1: string | unknown;
            p2: string | unknown;
            p3: string | unknown;
        };
        continue: string | unknown;
    };
    field: FieldTexts;
    confirm: {
        warning: string | unknown;
        confirm: string | unknown;
        cancel: string | unknown;
        irreversible: string | unknown;
    };
    config: {
        missing: string | unknown;
    };
}

export interface UseCommandProps {
    dark: boolean;
    texts: {
        header: HeaderTexts;
        footer: FooterTexts;
        command: UseCommandTexts;
        setupMenu: SetupMenuTexts;
        history: MessageHistoryTexts;
    };
    user?: UserI;
    page: string;
    language: string;
    path: string;
    guild: UserGuildI;
    command: UseCommandI;
    mode: "edit" | "create" | "copy";
    data?: Record<string, unknown>;
    message?: {
        message: string;
        channel: string;
    };
}

export default function UseCommand(props: UseCommandProps): React.ReactElement {
    const emojiPicker: React.RefObject<EmojiPicker> = React.createRef();
    const fieldsRefs: Record<string, React.RefObject<Field>> = {};

    const { command, guild, texts } = props;

    const [completeModeEnabled, setCompleteModeEnabled] = useState(false);
    const [mode, setMode] = useState(props.mode);
    const [message, setMessage] = useState(props.message);
    const [data, setData] = useState(props.data);
    const [setupData, setSetupData] = useState({});
    const [reset, setReset] = useState(0);

    const { showModal, hideModal } = useModal();
    const { addToast } = useToasts();

    const history = useRef<MessageHistory>();

    const changeCompleteMode = (d: { name: string; value: boolean }): void => {
        setCompleteModeEnabled(d.value);
    };

    const createNew = (): void => {
        setMode("create");
        setMessage(undefined);
        setData({ ...props.data, ...setupData });
        setCompleteModeEnabled(false);
        hideModal();
        setReset(reset + 1);
    };

    const confirm = (): Promise<boolean> =>
        new Promise((resolve) => {
            showModal(
                <>
                    <ModalElement.Title>{texts.command.confirm.warning}</ModalElement.Title>
                    <ModalElement.Description>{texts.command.confirm.irreversible}</ModalElement.Description>
                    <ModalElement.Buttons>
                        <button className="btn btn-primary" onClick={() => resolve(false)}>
                            {texts.command.confirm.cancel}
                        </button>
                        <button className="btn btn-danger" onClick={() => resolve(true)}>
                            {texts.command.confirm.confirm}
                        </button>
                    </ModalElement.Buttons>
                </>,
                {
                    appearence: "danger",
                    dismissable: "all",
                    onDismiss: () => resolve(false)
                }
            );
        });

    const submit = async (): Promise<void> => {
        hideModal();
        const values: Record<string, unknown> = {};
        if (mode === "edit" && message) values.edit = message;
        values.action = mode;
        let error = false;
        Object.entries(fieldsRefs).forEach(([name, field]) => {
            try {
                values[name] = field.current.getValue();
            } catch (e) {
                error = true;
            }
        });
        if (!error) {
            if ((command.config as Config).use.confirm && !(await confirm())) return;

            const result = await superagent.post(`./${props.command.link}/submit`).send(values).set("accept", "json");
            const resultData = result.body as { success: boolean; result: unknown; message: { id: string; channel: string; data: Record<string, unknown> } };
            if (resultData.success) {
                if (resultData.message) {
                    showModal(
                        <>
                            <ModalElement.Title>{texts.command.modals.success.title}</ModalElement.Title>
                            <ModalElement.Description>
                                <p>{resultData.result}</p>
                                <p>{texts.command.modals.succesWithMessage.description}</p>
                            </ModalElement.Description>
                            <ModalElement.Buttons>
                                <button className="btn btn-primary" onClick={createNew}>
                                    {texts.command.modals.continue}
                                </button>
                                <button className="btn btn-success" onClick={hideModal}>
                                    {texts.command.modals.succesWithMessage.edit}
                                </button>
                            </ModalElement.Buttons>
                        </>
                    );
                    if (history && history.current) history.current.loadMessage(resultData.message.id);
                    setMode("edit");
                    setMessage({
                        message: resultData.message.id,
                        channel: resultData.message.channel
                    });
                    setData({ ...resultData.message.data, ...setupData });
                } else {
                    showModal(
                        <>
                            <ModalElement.Title>{texts.command.modals.success.title}</ModalElement.Title>
                            <ModalElement.Description>
                                <p>{resultData.result}</p>
                            </ModalElement.Description>
                            <ModalElement.Buttons>
                                <button className="btn btn-success" onClick={createNew}>
                                    {texts.command.modals.continue}
                                </button>
                            </ModalElement.Buttons>
                        </>
                    );
                }
            } else {
                showModal(
                    <>
                        <ModalElement.Title>{texts.command.modals.error.title}</ModalElement.Title>
                        <ModalElement.Description>
                            <p>{texts.command.modals.error.p1}</p>
                            <p>{texts.command.modals.error.p2}</p>
                            <p dangerouslySetInnerHTML={{ __html: texts.command.modals.error.p3 as string }}></p>
                        </ModalElement.Description>
                        <ModalElement.Buttons>
                            <button className="btn btn-warning" onClick={hideModal}>
                                {texts.command.modals.continue}
                            </button>
                        </ModalElement.Buttons>
                    </>,
                    {
                        appearence: "danger"
                    }
                );
            }
        }
    };

    const onSetupChange = async (fieldData: { name: string; value: unknown }): Promise<void> => {
        setData({
            ...data,
            [fieldData.name]: fieldData.value === "none" ? undefined : fieldData.value
        });
        setSetupData({
            ...setupData,
            [fieldData.name]: fieldData.value === "none" ? undefined : fieldData.value
        });
        const result = await superagent.post(`./${command.link}`).send(fieldData).set("accept", "json");
        if (!result.body || result.body.result !== "OK") {
            //Echec
            addToast(texts.command.submit.setup.error as string, {
                appearance: "error",
                autoDismiss: true
            });
        } else {
            //Succès
            addToast(texts.command.submit.setup.success as string, {
                appearance: "success",
                autoDismiss: true
            });
        }
    };

    const missingConfiguration = command && (command.config as Config).setup && (command.config as Config).setup.fields.find((f) => f.fatal && (!data || !data[f.name]));

    useEffect(() => {
        if (command && command.authorized && missingConfiguration && !guild.admin) {
            showModal(
                <>
                    <ModalElement.Title>{texts.command.modals.error.title}</ModalElement.Title>
                    <ModalElement.Description>
                        {(command.config as Config).setup.fields.map((f, i) => {
                            if (f.fatal && (!data || !data[f.name])) return <p key={i}>{f.noDataMessage}</p>;
                            else return null;
                        })}
                    </ModalElement.Description>
                    <ModalElement.Buttons>
                        <button className="btn btn-warning" onClick={hideModal}>
                            {texts.command.modals.continue}
                        </button>
                    </ModalElement.Buttons>
                </>,
                {
                    appearence: "danger",
                    dismissable: "all"
                }
            );
        }
    }, []);

    return (
        <TemplateSetup {...props}>
            {command && (
                <div className="command-title-container" style={{ borderLeftColor: Categories[command.categoryEN], borderRightColor: Categories[command.categoryEN] }}>
                    <span className="command-title" style={{ backgroundColor: `${Categories[command.categoryEN]}b0` }}>
                        {command.name}
                    </span>
                </div>
            )}
            {(!command || !command.authorized) && (
                <div className="text-center m-5">
                    {!command && <div className="alert alert-danger">{texts.command.unknown}</div>}
                    {command && !command.authorized && <div className="alert alert-danger">{texts.command.noAccess}</div>}
                </div>
            )}
            {command && command.authorized && (
                <div>
                    <TabContainer defaultTab={(command.config as Config) && (command.config as Config).use ? "use" : "setup"}>
                        {(tabData: TabData) => (
                            <>
                                <Tabs>
                                    {(command.config as Config) && (command.config as Config).use && (
                                        <Tab tabData={tabData} name="use">
                                            {texts.command.tabs.use}
                                        </Tab>
                                    )}
                                    {(command.config as Config) && (command.config as Config).setup && guild.admin && (
                                        <Tab tabData={tabData} name="setup" className="tab-setup">
                                            {texts.command.tabs.setup}
                                        </Tab>
                                    )}
                                </Tabs>
                                <TabsContent>
                                    {(command.config as Config) && (command.config as Config).use && (
                                        <TabContent tabData={tabData} name="use" className={completeModeEnabled ? "complete-mode" : ""}>
                                            <>
                                                {missingConfiguration && (
                                                    <div className="alert alert-danger">
                                                        {(command.config as Config).setup.fields.map((f, i) => {
                                                            if (f.fatal && !data[f.name]) return <p key={i}>{f.noDataMessage}</p>;
                                                            else return null;
                                                        })}
                                                    </div>
                                                )}
                                                <div className="box">
                                                    {(command.config as Config).use.fields.find((f) => !f.required) && (
                                                        <div className="d-flex align-items-center m-4 complete-mode-input">
                                                            <span className="mr-3">{texts.command.compactMode}</span>
                                                            <CheckBoxField texts={texts.command.field} onChange={changeCompleteMode} style={{ display: "inline-block" }} />
                                                            <span className="ml-3">{texts.command.completeMode}</span>
                                                        </div>
                                                    )}
                                                    {(command.config as Config).use.fields.map((field, index) => {
                                                        const ref: React.RefObject<Field> = React.createRef();
                                                        const i = `${index}-${reset}`;
                                                        fieldsRefs[field.name] = ref;
                                                        if (mode === "edit" && field.unalterable) {
                                                            return (
                                                                <AnimateHeight key={i} duration={750} height={0}>
                                                                    <Field texts={texts.command.field} className="field-unalterable" ref={ref} field={{ ...field, required: false }} command={command} guild={guild} emojiPicker={emojiPicker} value={data ? data[field.name] : undefined} />
                                                                </AnimateHeight>
                                                            );
                                                        } else {
                                                            if (mode === "copy" || mode === "edit") {
                                                                return (
                                                                    <AnimateHeight key={i} duration={750} height={!completeModeEnabled && !field.required ? 0 : "auto"}>
                                                                        <Field texts={texts.command.field} ref={ref} field={field} command={command} guild={guild} emojiPicker={emojiPicker} value={data ? data[field.name] : undefined} />
                                                                    </AnimateHeight>
                                                                );
                                                            } else {
                                                                return (
                                                                    <AnimateHeight key={i} duration={750} height={!completeModeEnabled && !field.required ? 0 : "auto"}>
                                                                        <Field texts={texts.command.field} ref={ref} field={field} command={command} guild={guild} emojiPicker={emojiPicker} value={undefined} />
                                                                    </AnimateHeight>
                                                                );
                                                            }
                                                        }
                                                    })}
                                                    <div className="text-right">
                                                        <span className="p-1 small" style={{ backgroundColor: "rgb(59, 126, 239, 0.3)" }}>
                                                            * {texts.command.requiredField}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="text-right m-4">
                                                    <button className={`btn btn-success ${missingConfiguration ? "disabled" : ""}`} onClick={missingConfiguration ? undefined : submit}>
                                                        {mode === "edit" && texts.command.submit.edit}
                                                        {mode !== "edit" && (command.config as Config).use.submitText}
                                                        {mode !== "edit" && !(command.config as Config).use.submitText && texts.command.submit.create}
                                                    </button>
                                                </div>
                                                {(command.config as Config).use.history && <MessageHistory ref={history} texts={texts.history} command={command} />}
                                            </>
                                        </TabContent>
                                    )}
                                    {(command.config as Config) && (command.config as Config).setup && guild.admin && (
                                        <TabContent tabData={tabData} name="setup">
                                            <>
                                                <div className="box">
                                                    {(command.config as Config).setup.fields.map((field, index) => (
                                                        <Field key={index} onChange={onSetupChange} texts={texts.command.field} field={field} command={command} guild={guild} emojiPicker={emojiPicker} value={data ? data[field.name] : undefined} />
                                                    ))}
                                                </div>
                                                <div style={{ display: "flex", justifyContent: "flex-end", padding: "1rem" }}>
                                                    <button className="btn btn-success" onClick={() => tabData.setActiveTab("use")}>
                                                        {texts.command.submit.setup.submit}
                                                    </button>
                                                </div>
                                            </>
                                        </TabContent>
                                    )}
                                </TabsContent>
                            </>
                        )}
                    </TabContainer>
                    {/* <ul className="nav nav-tabs d-flex-md" role="tablist">
                        {(command.config as Config) && (command.config as Config).use && (
                            <li className="flex-fill nav-item col-6">
                                <a className={`nav-link text-center ${tab === "use" ? "active" : ""}`} data-toggle="tab" href="#use" role="tab" aria-controls="use" aria-selected={tab === "use" ? "true" : "false"}>
                                    {texts.command.tabs.use}
                                </a>
                            </li>
                        )}

                        {(command.config as Config) && (command.config as Config).setup && guild.admin && (
                            <li className="flex-fill nav-item col-6">
                                <a className={`nav-link text-center tab-setup ${tab === "setup" ? "active" : ""}`} data-toggle="tab" href="#setup" role="tab" aria-controls="setup" aria-selected={tab === "setup" ? "true" : "false"}>
                                    {texts.command.tabs.setup}
                                </a>
                            </li>
                        )}
                    </ul>

                    <div className="tab-content mt-3">
                        {(command.config as Config) && (command.config as Config).use && (
                            <div id="use" className={`tab-pane fade ${tab === "use" ? "show active" : ""} ${completeModeEnabled ? "complete-mode" : ""}`} role="tabpanel">
                                {missingConfiguration && (
                                    <div className="alert alert-danger">
                                        {(command.config as Config).setup.fields.map((f, i) => {
                                            if (f.fatal && !data[f.name]) return <p key={i}>{f.noDataMessage}</p>;
                                            else return null;
                                        })}
                                    </div>
                                )}
                                <div className="box">
                                    {(command.config as Config).use.fields.find((f) => !f.required) && (
                                        <div className="d-flex align-items-center m-4 complete-mode-input">
                                            <span className="mr-3">{texts.command.compactMode}</span>
                                            <CheckBoxField texts={texts.command.field} onChange={changeCompleteMode} style={{ display: "inline-block" }} />
                                            <span className="ml-3">{texts.command.completeMode}</span>
                                        </div>
                                    )}
                                    {(command.config as Config).use.fields.map((field, index) => {
                                        const ref: React.RefObject<Field> = React.createRef();
                                        const i = `${index}-${reset}`;
                                        fieldsRefs[field.name] = ref;
                                        if (mode === "edit" && field.unalterable) {
                                            return (
                                                <AnimateHeight key={i} duration={750} height={0}>
                                                    <Field texts={texts.command.field} className="field-unalterable" ref={ref} field={{ ...field, required: false }} command={command} guild={guild} emojiPicker={emojiPicker} value={data ? data[field.name] : undefined} />
                                                </AnimateHeight>
                                            );
                                        } else {
                                            if (mode === "copy" || mode === "edit") {
                                                return (
                                                    <AnimateHeight key={i} duration={750} height={!completeModeEnabled && !field.required ? 0 : "auto"}>
                                                        <Field texts={texts.command.field} ref={ref} field={field} command={command} guild={guild} emojiPicker={emojiPicker} value={data ? data[field.name] : undefined} />
                                                    </AnimateHeight>
                                                );
                                            } else {
                                                return (
                                                    <AnimateHeight key={i} duration={750} height={!completeModeEnabled && !field.required ? 0 : "auto"}>
                                                        <Field texts={texts.command.field} ref={ref} field={field} command={command} guild={guild} emojiPicker={emojiPicker} value={undefined} />
                                                    </AnimateHeight>
                                                );
                                            }
                                        }
                                    })}
                                    <div className="text-right">
                                        <span className="p-1 small" style={{ backgroundColor: "rgb(59, 126, 239, 0.3)" }}>
                                            * {texts.command.requiredField}
                                        </span>
                                    </div>
                                </div>
                                <div className="text-right m-4">
                                    <button className={`btn btn-success ${missingConfiguration ? "disabled" : ""}`} onClick={missingConfiguration ? undefined : submit}>
                                        {mode === "edit" && texts.command.submit.edit}
                                        {mode !== "edit" && (command.config as Config).use.submitText}
                                        {mode !== "edit" && !(command.config as Config).use.submitText && texts.command.submit.create}
                                    </button>
                                </div>
                                {(command.config as Config).use.history && <MessageHistory texts={texts.history} command={command} />}
                            </div>
                        )}
                        {(command.config as Config) && (command.config as Config).setup && guild.admin && (
                            <div id="setup" className={`tab-pane fade ${tab === "setup" ? "show active" : ""}`} role="tabpanel">
                                <div className="box">
                                    {(command.config as Config).setup.fields.map((field, index) => (
                                        <Field key={index} onChange={onSetupChange} texts={texts.command.field} field={field} command={command} guild={guild} emojiPicker={emojiPicker} value={data ? data[field.name] : undefined} />
                                    ))}
                                </div>
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <a className="btn btn-success" data-toggle="tab" href="#use" role="tab" aria-controls="use" aria-selected="true" onClick={() => setTab("use")}>
                                        {texts.command.submit.setup.submit}
                                    </a>
                                </div>
                            </div>
                        )}
                    </div> */}
                </div>
            )}
            <EmojiPicker ref={emojiPicker} guildEmojis={guild.emojis} guildIcon={guild.iconURL} />
        </TemplateSetup>
    );
}
