import React = require("react");
import { FooterTexts } from "../partials/Footer";
import { HeaderTexts } from "../partials/Header";
import Section from "../partials/Section";
import TemplateMain from "../templates/TemplateMain";
import CommandI from "../types/Command";
import UserI from "../types/User";

export interface FeaturesTexts {
    page: string | unknown;
    title: string | unknown;
    commands: {
        admin: string | unknown;
        permissions: string | unknown;
        all: string | unknown;
    };
}

export interface FeaturesProps {
    dark: boolean;
    texts: {
        header: HeaderTexts;
        footer: FooterTexts;
        features: FeaturesTexts;
    };
    user?: UserI;
    page: string;
    language: string;
    path: string;
    commands: Array<CommandI>;
}

export default class Features extends React.Component<FeaturesProps> {

    constructor(props: FeaturesProps) {
        super(props);
        this.state = {
            apiResponse: ""
        };
    }

    render(): React.ReactNode {
        const { texts, commands } = this.props;
        let key = 0;
        return (
            <TemplateMain {...this.props}>
                <Section style={{ marginTop: "100px" }}>
                    <h1 className="text-center">{texts.features.title}</h1>
                </Section>
                <Section>
                    <h3>{texts.features.commands.admin}</h3>
                    <table className="table">
                        <tbody>
                            {commands.map((command) =>
                                command.permissions === "Admin" ? (
                                    <tr key={key++}>
                                        <td>{command.name}</td>
                                        <td>
                                            {command.aliases.map((alias) => (
                                                <span key={key++} className="badge bg-secondary text-white m-1">
                                                    {alias}
                                                </span>
                                            ))}
                                        </td>
                                        <td>{command.description}</td>
                                    </tr>
                                ) : undefined
                            )}
                        </tbody>
                    </table>
                </Section>
                <Section>
                    <h3>{texts.features.commands.permissions}</h3>
                    <table className="table">
                        <tbody>
                            {commands.map((command) =>
                                command.permissions !== 'Admin' && command.permissions > -1 ? (
                                    <tr key={key++}>
                                        <td>{command.name}</td>
                                        <td>
                                            {command.aliases.map((alias) => (
                                                <span key={key++} className="badge bg-secondary text-white m-1">
                                                    {alias}
                                                </span>
                                            ))}
                                        </td>
                                        <td>{command.description}</td>
                                    </tr>
                                ) : undefined
                            )}
                        </tbody>
                    </table>
                </Section>
                <Section>
                    <h3>{texts.features.commands.all}</h3>
                    <table className="table">
                        <tbody>
                            {commands.map((command) =>
                                command.permissions === -1 ? (
                                    <tr key={key++}>
                                        <td>{command.name}</td>
                                        <td>
                                            {command.aliases.map((alias) => (
                                                <span key={key++} className="badge bg-secondary text-white m-1">
                                                    {alias}
                                                </span>
                                            ))}
                                        </td>
                                        <td>{command.description}</td>
                                    </tr>
                                ) : undefined
                            )}
                        </tbody>
                    </table>
                </Section>
            </TemplateMain>
        );
    }

}
