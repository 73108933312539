import React from "react";
import AnimateHeight from "react-animate-height";
import superagent from "superagent";
import ChannelI from "../types/Channel";
import CommandI from "../types/Command";
import UserI from "../types/User";
import ToolTipContainer, { ToolTip, ToolTipPosition } from "../utils/ToolTip";

export interface HistoryMessage {
    timestamp: number;
    editable: boolean;
    author: UserI;
    editedBy: UserI;
    channel: ChannelI;
    editingDate: string;
    sendingDate: string;
    description: string;
    id: string;
    deleted: boolean;
}

export interface MessageHistoryTexts {
    delete: string | unknown;
    cancel: string | unknown;
    title: string | unknown;
    edited: string | unknown;
    actions: {
        edit: string | unknown;
        delete: string | unknown;
        copy: string | unknown;
    };
    search: {
        search: string | unknown;
        refine: string | unknown;
    };
    noResults: string|unknown;
}

export interface MessageHistoryProps {
    texts: MessageHistoryTexts;
    command: CommandI;
}

export interface MessageHistorySate {
    messages: Array<HistoryMessage>;
    search: Array<HistoryMessage>;
    status: "loading" | "idle" | "ended";
    searchString: string;
}

export default class MessageHistory extends React.Component<MessageHistoryProps, MessageHistorySate> {

    searchInput: React.RefObject<HTMLInputElement> = React.createRef();

    constructor(props: MessageHistoryProps) {
        super(props);
        this.state = {
            messages: [],
            search: [],
            status: "idle",
            searchString: ""
        };
    }

    render(): React.ReactNode {
        const { texts } = this.props;
        const messages = this.state.searchString.length > 0 ? this.state.search : this.state.messages;
        return (
            <div>
                <h3 className="text-center m-4">{texts.title}</h3>

                <div className="history-search">
                    <input className="form-control history-search-input" placeholder={texts.search.search as string} ref={this.searchInput} defaultValue={this.state.searchString} type="text" onInput={this.search.bind(this)} />
                    <button className="btn btn-secondary history-search-clear" onClick={this.clearSearch.bind(this)}>
                        <i className="fa fa-times"></i>
                    </button>
                    {this.state.searchString.length > 0 && this.state.searchString.length < 4 && <span className="small text-danger history-search-refine">{texts.search.refine}</span>}
                </div>

                {messages.length == 0 && (
                    <p className="history-no-results">{texts.noResults}</p>
                )}
                {messages.map((message, index) => (
                    <div key={index} className="history-message-container">
                        <div className="history-message">
                            <div className="history-message-author-details">
                                <img src={message.author.avatarURL} className="history-message-author-icon" />
                                <span className="history-message-author-name small">{message.author.username}</span>
                            </div>

                            <ToolTipContainer className="history-message-infos">
                                <ToolTip position={ToolTipPosition.TOP_LEFT}>
                                    <div className="text-center">{message.sendingDate}</div>
                                </ToolTip>
                                <span className="history-message-channel" style={{ color: "var(--channel)" }}>
                                    {message.channel.name}
                                </span>
                                {message.description && <span className="history-messafe-details small">{message.description}</span>}
                            </ToolTipContainer>
                            <div className="history-message-controls">
                                {message.editingDate && message.editedBy && (
                                    <ToolTipContainer>
                                        <ToolTip>
                                            <div className="text-center">{message.editedBy.username}</div>
                                            <div className="text-center">{message.editingDate}</div>
                                        </ToolTip>
                                        <span className="history-message-edited small text-muted">{texts.edited}</span>
                                    </ToolTipContainer>
                                )}
                                {message.editable && (
                                    <ToolTipContainer>
                                        <ToolTip>{texts.actions.edit}</ToolTip>
                                        <a className="btn btn-success m-2 btn-sm" href={`./message/edit?message=${message.id}&channel=${message.channel.id}`}>
                                            <i className="fas fa-edit"></i>
                                        </a>
                                    </ToolTipContainer>
                                )}
                                {message.editable && (
                                    <ToolTipContainer>
                                        <ToolTip>{texts.actions.delete}</ToolTip>
                                        <span className="btn btn-danger m-2 btn-sm" data-message={message.id} onClick={this.delete.bind(this)}>
                                            <i className="fas fa-trash"></i>
                                        </span>
                                    </ToolTipContainer>
                                )}
                                <ToolTipContainer>
                                    <ToolTip>{texts.actions.copy}</ToolTip>
                                    <a className="btn btn-primary m-2 btn-sm" href={`./message/copy?message=${message.id}&channel=${message.channel.id}`}>
                                        <i className="fas fa-copy"></i>
                                    </a>
                                </ToolTipContainer>
                            </div>
                        </div>
                        {message.deleted && (
                            <div className="history-message-confirm-delete">
                                <span data-message={message.id} onClick={this.cancelDelete.bind(this)} className="small text-light">
                                    <span className="btn btn-success btn-sm">
                                        <i className="fas fa-undo"></i>
                                    </span>
                                    {texts.cancel}
                                </span>
                                <span data-message={message.id} onClick={this.confirmDelete.bind(this)} className="small text-light">
                                    <span className="btn btn-danger btn-sm">
                                        <i className="fas fa-trash"></i>
                                    </span>
                                    {texts.delete}
                                </span>
                            </div>
                        )}
                    </div>
                ))}
                <AnimateHeight duration={500} height={this.state.status === "ended" ? 0 : "auto"}>
                    <div className="text-center pt-4">
                        <div onClick={this.load.bind(this)} className="history-load-more">
                            <div className={`history-loading ${this.state.status === "loading" ? "active" : ""}`}></div>
                        </div>
                    </div>
                </AnimateHeight>
            </div>
        );
    }

    componentDidMount(): void {
        this.load();
    }

    clearSearch(): void {
        this.setState({
            searchString: "",
            search: [],
            status: "idle"
        });
        this.searchInput.current.value = "";
    }

    async search(): Promise<void> {
        const search = this.searchInput.current.value;
        this.setState({
            searchString: search
        });
        if (search.length <= 3) {
            this.setState({
                search: []
            });
            return;
        }
        const messages = await this.request({ search: search });
        this.setState({
            search: messages,
            status: "ended"
        });
    }

    delete(event: React.MouseEvent): void {
        const id = (event.currentTarget as HTMLElement).dataset.message;
        if (id) {
            const messages = this.state.messages.map((i) => {
                if (i.id === id) i.deleted = true;
                return i;
            });

            this.setState({
                messages: messages
            });
        }
    }

    cancelDelete(event: React.MouseEvent): void {
        const id = (event.currentTarget as HTMLElement).dataset.message;
        if (id) {
            const messages = this.state.messages.map((i) => {
                if (i.id === id) i.deleted = false;
                return i;
            });

            this.setState({
                messages: messages
            });
        }
    }

    async confirmDelete(event: React.MouseEvent): Promise<void> {
        const id = (event.currentTarget as HTMLElement).dataset.message;
        if (id) {
            const message = this.state.messages.find((m) => m.id === id);
            const messages = this.state.messages.filter((i) => i.id !== id);
            const result = await superagent.get(`./message/delete?message=${message.id}&channel=${message.channel.id}`).set("accept", "json");
            if (result.body.result === "OK") {
                this.setState({
                    messages: messages
                });
                if (messages.length == 0) this.load();
            }
        }
    }

    public async loadMessage(message: string): Promise<void> {
        const messages = await this.request({ id: message });
        if (messages.length > 0) {
            const result = [...messages, ...this.state.messages].sort((a, b) => b.timestamp - a.timestamp).filter((m, i, a) => i === a.indexOf(a.find((x) => x.id === m.id)));
            this.setState({
                messages: result,
                status: "idle"
            });
        }
    }

    async load(): Promise<void> {
        this.setState({
            messages: [...this.state.messages, ...(await this.getMessages())]
        });
    }

    async getMessages(start?: number, count?: number): Promise<Array<HistoryMessage>> {
        if (!start) start = this.state.messages.length;
        if (!count) count = 5;
        const messages = await this.request({ start: start, count: count });
        if (messages.length < count) {
            this.setState({
                status: "ended"
            });
        } else {
            this.setState({
                status: "idle"
            });
        }
        return messages;
    }

    async request(data: Record<string, unknown>): Promise<Array<HistoryMessage>> {
        this.setState({
            status: "loading"
        });
        const result = await superagent.post(`./${this.props.command.link}/history`).send(data).set("accept", "json");
        const messages: Array<HistoryMessage> = result.body;

        return messages;
    }

}
