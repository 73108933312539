import React = require("react");
import AnimateHeight from "react-animate-height";
import CommandI, { FieldI } from "../../types/Command";
import UserGuildI from "../../types/UserGuild";
import { UIField, UIFieldProps } from "./Field";

export interface ImageFieldTexts {
    noAccess: string | unknown;
}

export interface ImageFieldProps extends UIFieldProps {
    command: CommandI;
    guild: UserGuildI;
    field: FieldI;
    value?: string;
}

export interface ImageFieldState {
    check: "valid" | "loading" | "invalid";
    image: string;
    invalid: boolean;
}

export default class ImageField extends React.Component<ImageFieldProps, ImageFieldState> implements UIField {

    private input: React.RefObject<HTMLInputElement> = React.createRef();

    constructor(props: ImageFieldProps) {
        super(props);
        this.state = {
            check: undefined,
            image: undefined,
            invalid: false
        };
    }

    render(): React.ReactNode {
        const { field, value, texts } = this.props;
        return (
            <div>
                <div className="input-group">
                    <div className="input-group-prepend">
                        <div className={`input-group-text ${this.state.invalid ? "field-invalid" : ""}`} style={{ minWidth: "2.4rem" }}>
                            <img className="rounded-circle" style={{ maxWidth: "24px", maxHeight: "24px", display: field.preview && this.state.check === "valid" ? "block" : "none" }} src={this.state.image || value} onError={this.error.bind(this)} onLoad={this.success.bind(this)} />
                            {this.state.check === "valid" && !field.preview && <i className="fas fa-check text-success" style={{ maxWidth: "24px", maxHeight: "24px" }}></i>}
                            {this.state.check === "invalid" && <i className="fas fa-times text-danger" style={{ maxWidth: "24px", maxHeight: "24px" }}></i>}
                            {this.state.check === "loading" && <i className="fas fa-spinner spinning " style={{ maxWidth: "24px", maxHeight: "24px" }}></i>}
                        </div>
                    </div>
                    <input ref={this.input} type="url" onChange={this.verifyUrl.bind(this)} maxLength={field.maxLength} pattern="https?://.+" className={`form-control ${this.state.invalid ? "field-invalid" : ""}`} name={field.name} defaultValue={value || (field.default as string)} required={field.required} />
                </div>
                <AnimateHeight height={this.state.invalid ? "auto" : 0}>
                    <small className="field-error">{texts.error.invalidImage}</small>
                </AnimateHeight>
            </div>
        );
    }

    componentDidMount(): void {
        if (this.props.value || this.props.field.default) {
            this.verifyUrl(({
                currentTarget: this.input.current
            } as unknown) as React.ChangeEvent);
        }
    }

    verifyUrl(event: React.ChangeEvent): void {
        const value: string = (event.currentTarget as HTMLInputElement).value;
        this.setState({
            check: value ? "loading" : undefined,
            image: value
        });
    }

    error(): void {
        this.setState({
            check: "invalid"
        });
    }

    success(): void {
        this.setState({
            check: "valid"
        });
        if (this.checkValue()) this.triggerOnChange(this.state.image);
    }

    triggerOnChange(value: string): void {
        if (this.props.onChange) {
            this.props.onChange({
                name: this.props.field.name,
                value: value
            });
        }
    }

    checkValue(value?: string): boolean {
        if (!value) value = this.state.image;
        const result = !(this.props.field.required && (!value || this.state.check !== "valid"));
        if (result) {
            this.setState({
                invalid: false
            });
        }
        return result;
    }

    public getValue(): string {
        if (!this.checkValue()) {
            this.displayError();
            throw new Error();
        }
        return this.state.image;
    }

    public displayError(): void {
        this.setState({
            invalid: true
        });
    }

}
