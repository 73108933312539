import React = require("react");
import Footer, { FooterTexts } from "../partials/Footer";
import Head from "../partials/Head";
import Header, { HeaderTexts } from "../partials/Header";
import Section, { SectionColor } from "../partials/Section";
import UserI from "../types/User";

export interface MainProps {
    dark: boolean;
    texts: {
        header: HeaderTexts;
        footer: FooterTexts;
    };
    user?: UserI;
    page: string;
    language: string;
    path: string;
}

export default class TemplateMain extends React.Component<MainProps, Record<string, unknown>> {

    render(): React.ReactNode {
        Section.sections = {
            number: 0,
            last: undefined
        };
        const { texts, user, dark, page, language, children, path } = this.props;
        return (
            <html>
                <Head page={page} />
                <body data-theme={dark ? "dark" : "ligth"}>
                    <Header texts={texts.header} user={user} theme={dark ? "dark" : "light"} language={language} />
                    {children}
                    <div className="text-center" style={{ marginTop: "50px", marginBottom: "50px" }}>
                        {texts.footer.join.text}
                        <br />
                        <a href="https://discord.gg/AHEsw8rguJ" target="_blank" className="btn btn-primary btn-glow-animate mt-3">
                            {texts.footer.join.button}
                        </a>
                    </div>
                    <Footer texts={texts.footer} />
                    <script dangerouslySetInnerHTML={{ __html: `window.__APP_INITIAL_STATE__ = ${JSON.stringify(this.props, this.appInitialStateFilter)}` }}></script>
                    <script src="/assets/bundle/bundle.js" />
                </body>
            </html>
        );
    }

    appInitialStateFilter(name: string, val: unknown): unknown {
        if (name === "children") return undefined;
        else return val;
    }

}
