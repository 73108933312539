import React = require("react");

export interface FooterTexts {
    legal: string | unknown;
    join: {
        text: string | unknown;
        button: string | unknown;
    };
}

export interface FooterProps {
    texts: FooterTexts;
}

export default class Footer extends React.Component<FooterProps, Record<string, unknown>> {

    render(): React.ReactNode {
        const texts = this.props.texts;
        return (
            <footer className="bg-light m-2">
                <hr className="m-0" />
                <div className="container p-4 text-center">
                    <span className="text-muted">
                        © 2021 Copyright&nbsp;
                        <a href="https://xebot.fr">xebot.fr</a> -{" "}
                        <a className="unstyled-link" href="/legal">
                            {texts.legal}
                        </a>
                    </span>
                </div>
            </footer>
        );
    }

}
