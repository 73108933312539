import React = require("react");
import CommandI, { FieldI } from "../../types/Command";
import UserGuildI from "../../types/UserGuild";
import { UIField, UIFieldProps } from "./Field";

export interface CheckBoxFieldProps extends UIFieldProps {
    command?: CommandI;
    guild?: UserGuildI;
    field?: FieldI;
    value?: boolean;
    style?: React.CSSProperties;
}

export interface CheckBoxFieldState {
    checked: boolean;
}

export default class CheckBoxField extends React.Component<CheckBoxFieldProps, CheckBoxFieldState> implements UIField {

    constructor(props: CheckBoxFieldProps) {
        super(props);
        this.state = {
            checked: props.value === undefined ? (props.field ? (props.field.default as boolean) : undefined) : props.value
        };
    }

    render(): React.ReactNode {
        return (
            <div className={`custom-input-checkbox ${this.state.checked ? "checked" : ""}`} style={this.props.style}>
                <input type="checkbox" className="custom-input-checkbox-input" />
                <span className="custom-input-checkbox-span" onClick={this.updateCheck.bind(this)}>
                    <svg className="checked" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 426.67 426.67">
                        <path d="M153.504 366.84c-8.657 0-17.323-3.303-23.927-9.912L9.914 237.265c-13.218-13.218-13.218-34.645 0-47.863 13.218-13.218 34.645-13.218 47.863 0l95.727 95.727 215.39-215.387c13.218-13.214 34.65-13.218 47.86 0 13.22 13.218 13.22 34.65 0 47.863L177.435 356.928c-6.61 6.605-15.27 9.91-23.932 9.91z" />
                    </svg>
                </span>
            </div>
        );
    }

    updateCheck(): void {
        const value = !this.state.checked;
        this.setState({
            checked: value
        });
        this.triggerOnChange(value);
    }

    triggerOnChange(value: boolean): void {
        if (this.props.onChange) {
            this.props.onChange({
                name: this.props.field ? this.props.field.name : undefined,
                value: value
            });
        }
    }

    checkValue(): boolean {
        return true;
    }

    public getValue(): boolean {
        return !!this.state.checked;
    }

    public displayError(): void {
        //Impossible
    }

}
