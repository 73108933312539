import React = require("react");
import AnimateHeight from "react-animate-height";
import CommandI, { FieldI } from "../../types/Command";
import UserGuildI from "../../types/UserGuild";
import { UIField, UIFieldProps } from "./Field";

export interface NumberFieldProps extends UIFieldProps {
    command: CommandI;
    guild: UserGuildI;
    field: FieldI;
    value?: number;
}

export interface NumberFieldState {
    invalid: boolean;
    value: number;
}

export default class NumberField extends React.Component<NumberFieldProps, NumberFieldState> implements UIField {

    constructor(props: NumberFieldProps) {
        super(props);
        this.state = {
            invalid: false,
            value: this.props.value || (this.props.field.default as number)
        };
    }

    render(): React.ReactNode {
        const { field, texts } = this.props;
        return (
            <div>
                <input type="number" required={field.required} defaultValue={this.state.value} onChange={this.updateValue.bind(this)} name={field.name} className={`form-control ${this.state.invalid ? "field-invalid" : ""}`} />

                <AnimateHeight height={this.state.invalid ? "auto" : 0}>
                    <small className="field-error">{texts.error.invalidNumber}</small>
                </AnimateHeight>
            </div>
        );
    }

    updateValue(event: React.ChangeEvent): void {
        const value = parseInt((event.currentTarget as HTMLInputElement).value);
        if (value !== this.state.value) {
            this.setState({
                value: isNaN(value) ? 0 : value
            });
            if (this.checkValue()) this.triggerOnChange(isNaN(value) ? 0 : value);
        }
    }

    triggerOnChange(value: number): void {
        if (this.props.onChange) {
            this.props.onChange({
                name: this.props.field.name,
                value: value
            });
        }
    }

    checkValue(): boolean {
        const result = !(this.props.field.required && this.state.value === undefined && !isNaN(this.state.value));
        if (result) {
            this.setState({
                invalid: false
            });
        }
        return result;
    }

    public getValue(): number {
        if (!this.checkValue()) {
            this.displayError();
            throw new Error();
        }
        const value = this.state.value;
        return isNaN(value) ? 0 : value;
    }

    displayError(): void {
        this.setState({
            invalid: true
        });
    }

}
