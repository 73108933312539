import React = require("react");
import CommandI, { FieldI } from "../../types/Command";
import UserGuildI from "../../types/UserGuild";
import EmojiPicker from "../../utils/EmojiPicker";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import Field, { FieldTexts, UIField, UIFieldProps } from "./Field";
import AnimateHeight from "react-animate-height";

export interface ListProps extends UIFieldProps {
    command: CommandI;
    guild: UserGuildI;
    field: FieldI;
    emojiPicker: React.RefObject<EmojiPicker>;
    value: Array<Record<string | number, unknown>>;
    texts: FieldTexts;
}

export interface ListState {
    items: Array<ListItem>;
    editing: boolean;
}

export interface ListItem {
    id: string;
    value: Record<string | number, unknown>;
    deleted: boolean;
    editing: boolean;
    hidden: boolean;
    fields: Record<string, React.RefObject<Field>>;
}

const ConfirmDelete = (props: { item: ListItem; onConfirm: () => void; onCancel: () => void; texts: FieldTexts }) => (
    <div className="list-confirm-delete">
        <span data-item-id={props.item.id} onClick={props.onCancel} className="small text-light">
            <span className="btn btn-success btn-sm">
                <i className="fas fa-undo"></i>
            </span>
            {props.texts.cancel}
        </span>
        <span data-item-id={props.item.id} onClick={props.onConfirm} className="small text-light">
            <span className="btn btn-danger btn-sm">
                <i className="fas fa-trash"></i>
            </span>
            {props.texts.delete}
        </span>
    </div>
);

const MoveItem = () => <i className="fas fa-grip-lines list-move-item"></i>;
const DeleteItem = (props: { item: ListItem; onClick: () => void }) => <i className="fas fa-trash list-delete-item" data-item-id={props.item.id} onClick={props.onClick}></i>;
const EditItem = (props: { item: ListItem; onClick: () => void; texts: FieldTexts }) => (
    <span className="btn btn-primary text-light list-edit-item" data-item-id={props.item.id} onClick={props.onClick}>
        <i className="fas fa-edit"></i>
    </span>
);

const Header = (props: { field: FieldI; item: ListItem; guild: UserGuildI; texts: FieldTexts }) => {
    const formatValue = (field: FieldI, value: unknown): unknown => {
        if (!field) return "";
        switch (field.type) {
            case "role":
                const role = (value as { name: string; color: string }).name ? (value as { name: string; color: string }) : props.guild.roles.find((r) => r.id === value);
                if (role) return <span style={{ color: role.color }}>@{role.name}</span>;
                break;
            case "channel":
                const channel = (value as { name: string }).name ? (value as { name: string }) : props.guild.channels.find((r) => r.id === value);
                if (channel) return <span style={{ color: "var(--channel)" }}>{channel.name}</span>;
                break;
            case "list":
                if (Array.isArray(value)) return value.length;
                break;
            case "check":
                if (value === true || value === "true" || value === 1 || value === "1") return "on";
                else return "off";
                break;
            default:
                return value;
        }
    };
    let firstTitle = true;
    let firstDescription = true;
    let noData = true;
    return (
        <>
            <span className="list-header-title">
                {Object.keys(props.item.fields).map((name, i) => {
                    const field = props.field.fields.find((f) => f.name === name);
                    let res = i === Object.keys(props.item.fields).length - 1 && noData ? props.texts.noData : null;
                    if (!field) return res;
                    const v = props.item.value ? props.item.value[name] : undefined;
                    if (v === undefined) return res;
                    if (field.listPreview === "title") {
                        const text = (field.listPreviewText as string) || "{{value}}";
                        const before = (firstTitle ? "" : " | ") + text.slice(0, text.indexOf("{{value}}"));
                        const after = text.slice(text.indexOf("{{value}}") + "{{value}}".length);
                        res = (
                            <span key={i}>
                                {before}
                                {formatValue(field, props.item.value[name])}
                                {after}
                            </span>
                        );
                    }
                    if (res) firstTitle = false;
                    noData = false;
                    return res;
                })}
            </span>
            <span className="list-header-description small">
                {Object.keys(props.item.fields).map((name, i) => {
                    const field = props.field.fields.find((f) => f.name === name);
                    if (!field) return null;
                    const v = props.item.value ? props.item.value[name] : undefined;
                    if (v === undefined) return null;
                    let res = null;
                    if (field.listPreview === "description") {
                        const text = (field.listPreviewText as string) || "{{value}}";
                        const before = (firstDescription ? "" : " | ") + text.slice(0, text.indexOf("{{value}}"));
                        const after = text.slice(text.indexOf("{{value}}") + "{{value}}".length);
                        res = (
                            <span key={i}>
                                {before}
                                {formatValue(field, props.item.value[name])}
                                {after}
                            </span>
                        );
                    }
                    if (res) firstDescription = false;
                    return res;
                })}
            </span>
        </>
    );
};

export default class List extends React.Component<ListProps, ListState> implements UIField {

    static ANIMATION_DURATION = 500;
    private nextId = 0;

    constructor(props: ListProps) {
        super(props);
        const { field } = props;
        let { value } = props;
        if (!value) value = [];
        const items: Array<ListItem> = [];
        let i = 0;
        value.forEach((it) => {
            if (it.listId) {
                const v = parseInt(`${it.listId}`);
                if (v > this.nextId) this.nextId = v;
            }
        });
        do {
            this.nextId++;
            items.push({
                id: value[i] && value[i].listId !== undefined ? `${value[i].listId}` : `${this.nextId++}`,
                value: value[i],
                deleted: false,
                editing: false,
                hidden: false,
                fields: {}
            });
            i++;
        } while (i < field.min || i < value.length);

        items.push({
            id: `${this.nextId++}`,
            value: value[i],
            deleted: false,
            editing: true,
            hidden: true,
            fields: {}
        });

        this.state = {
            items: items,
            editing: false
        };
    }

    render(): React.ReactNode {
        const { field, texts } = this.props;
        const { items } = this.state;
        const newField = Object.assign({}, field);
        Object.assign(newField, {
            type: field.listType
        });
        return (
            <DragDropContext onDragEnd={this.dragEnd.bind(this)}>
                <Droppable droppableId="list">
                    {(dropProvided) => (
                        <div {...dropProvided.droppableProps} ref={dropProvided.innerRef} className={`list ${this.state.editing ? "editing" : ""}`}>
                            {items.map((item, index) => (
                                <Draggable key={item.id} draggableId={item.id} index={index} isDragDisabled={this.state.editing || item.hidden}>
                                    {(dragProvided, dragSnapshot) => (
                                        <div ref={dragProvided.innerRef} {...dragProvided.draggableProps} {...dragProvided.dragHandleProps} className={`list-item ${dragSnapshot.isDragging ? "dragged" : ""} ${item.deleted ? "deleted" : ""} ${item.editing ? "editing" : ""} ${field.inline ? "inline" : ""}`} style={{ ...dragProvided.draggableProps.style }}>
                                            {field.inline && (
                                                <AnimateHeight duration={750} height={item.hidden ? 0 : "auto"}>
                                                    <div className="list-item-content-wrapper">
                                                        <MoveItem />
                                                        <span className="list-item-content inline box">
                                                            {field.fields.map((f, fIndex) => {
                                                                const ref: React.RefObject<Field> = React.createRef();
                                                                item.fields[f.name] = ref;
                                                                return <Field texts={texts} ref={ref} onChange={this.updateValue.bind(this)} key={fIndex} field={f} guild={this.props.guild} command={this.props.command} emojiPicker={this.props.emojiPicker} value={item.value ? item.value[f.name] : undefined} disableLabels={true} />;
                                                            })}
                                                        </span>
                                                        {index + 1 > (field.min || 0) && <DeleteItem item={item} onClick={this.deleteItem.bind(this)} />}
                                                        {item.deleted && <ConfirmDelete texts={texts} item={item} onCancel={this.cancelDelete.bind(this)} onConfirm={this.confirmDelete.bind(this)} />}
                                                    </div>
                                                </AnimateHeight>
                                            )}
                                            {!field.inline && (
                                                <AnimateHeight duration={750} height={item.hidden ? 0 : "auto"}>
                                                    <div className="list-item-content-wrapper">
                                                        <AnimateHeight height={this.state.editing ? 0 : "auto"} duration={List.ANIMATION_DURATION}>
                                                            <div className="list-item-header">
                                                                <MoveItem />
                                                                <span className="list-item-header-content">
                                                                    <Header item={item} field={field} guild={this.props.guild} texts={texts} />
                                                                </span>
                                                                <EditItem texts={texts} item={item} onClick={this.editItem.bind(this)} />
                                                                {index + 1 > (field.min || 0) && <DeleteItem item={item} onClick={this.deleteItem.bind(this)} />}
                                                                {item.deleted && <ConfirmDelete texts={texts} item={item} onCancel={this.cancelDelete.bind(this)} onConfirm={this.confirmDelete.bind(this)} />}
                                                            </div>
                                                        </AnimateHeight>
                                                        <AnimateHeight height={item.editing ? "auto" : 0} duration={List.ANIMATION_DURATION}>
                                                            <div className="list-item-body">
                                                                <button className="btn btn-sm w-100 btn-primary" data-item-id={item.id} onClick={this.exitEditing.bind(this)}>
                                                                    {texts.returnToOverview}
                                                                </button>
                                                                {field.fields.map((f, fIndex) => {
                                                                    const ref: React.RefObject<Field> = React.createRef();
                                                                    item.fields[f.name] = ref;
                                                                    return <Field texts={texts} ref={ref} onChange={this.updateValue.bind(this)} key={fIndex} field={f} guild={this.props.guild} command={this.props.command} emojiPicker={this.props.emojiPicker} value={item.value ? item.value[f.name] : undefined} />;
                                                                })}
                                                                <button className="btn btn-sm w-100 btn-primary" data-item-id={item.id} onClick={this.exitEditing.bind(this)}>
                                                                    {texts.returnToOverview}
                                                                </button>
                                                            </div>
                                                        </AnimateHeight>
                                                    </div>
                                                </AnimateHeight>
                                            )}
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {dropProvided.placeholder}
                            {(!field.max || items.length < field.max) && !this.state.editing && <div onClick={this.addField.bind(this)} className="list-add-item"></div>}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        );
    }

    addField(): void {
        const items = this.state.items.map((i) => {
            if (i.hidden) i.hidden = false;
            return i;
        });
        this.setState({
            items: [...items, { id: `${this.nextId++}`, value: undefined, deleted: false, editing: true, hidden: true, fields: {} }],
            editing: !this.props.field.inline
        });
    }

    reorder(list: Array<ListItem>, startIndex: number, endIndex: number): Array<ListItem> {
        if (list[endIndex].hidden) endIndex--;
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    }

    dragEnd(result: DropResult): void {
        // dropped outside the list
        if (!result.destination) return;

        const items = this.reorder(this.state.items, result.source.index, result.destination.index);
        items[result.destination.index].deleted = false;

        this.setState({
            items: items
        });
    }

    editItem(event: React.MouseEvent): void {
        const id = (event.currentTarget as HTMLElement).dataset.itemId;
        if (id) {
            const items = this.state.items.map((i) => {
                if (i.id === id) i.editing = true;
                return i;
            });

            this.setState({
                items: items,
                editing: true
            });
        }
    }

    exitEditing(event: React.MouseEvent): void {
        const id = (event.currentTarget as HTMLElement).dataset.itemId;
        if (id) {
            const items = this.state.items.map((i) => {
                if (i.id === id) i.editing = false;
                return i;
            });

            this.setState({
                items: items,
                editing: false
            });
        }
    }

    deleteItem(event: React.MouseEvent): void {
        const id = (event.currentTarget as HTMLElement).dataset.itemId;
        if (id) {
            const items = this.state.items.map((i) => {
                if (i.id === id) i.deleted = true;
                return i;
            });

            this.setState({
                items: items
            });
        }
    }

    cancelDelete(event: React.MouseEvent): void {
        const id = (event.currentTarget as HTMLElement).dataset.itemId;
        if (id) {
            const items = this.state.items.map((i) => {
                if (i.id === id) i.deleted = false;
                return i;
            });

            this.setState({
                items: items
            });
        }
    }

    confirmDelete(event: React.MouseEvent): void {
        const id = (event.currentTarget as HTMLElement).dataset.itemId;
        if (id) {
            const deleted = this.state.items.find((i) => i.id === id);
            const items = this.state.items.filter((i) => i.id !== id);

            this.valueChanged(deleted, "remove");

            this.setState(
                {
                    items: items
                },
                () => {
                    if (items.length === 1) this.addField();
                }
            );
        }
    }

    updateValue(data: { name: string; value: unknown; id: string }): void {
        try {
            this.getValue(true);
            this.valueChanged(data, "update");
            const items = this.state.items.map((i) => {
                if (Object.values(i.fields).find((f) => f.current.getId() === data.id)) i.value[data.name] = data.value;
                return i;
            });
            this.setState({
                items: items
            });
        } catch (e) {}
    }

    public valueChanged(data: { name: string; value: unknown; id: string } | ListItem, action: "update" | "remove"): void {
        if (action === "update") {
            this.triggerOnChange({
                name: this.props.field.name,
                value: {
                    ...data,
                    listId: this.state.items.find((it) => Object.values(it.fields).find((fi) => fi.current.getId() === data.id)).id,
                    action: action
                }
            });
        } else {
            this.triggerOnChange({
                name: this.props.field.name,
                value: {
                    listId: data.id,
                    action: action
                }
            });
        }
    }

    public triggerOnChange(data: { [key: string]: unknown; name: string; value: unknown }): void {
        if (this.props.onChange) this.props.onChange(data);
    }

    checkValue(): boolean {
        return true;
    }

    public getValue(disableErrorDisplay?: boolean): Array<{ id: string; values: Record<string, unknown> }> {
        const values: Array<{ id: string; values: Record<string, unknown> }> = [];
        let error = false;
        this.state.items.forEach((item, index) => {
            const fieldsValues: Record<string, unknown> = {};
            Object.entries(item.fields).forEach(([name, field]) => {
                try {
                    const value = field.current.getValue();
                    if (!value && this.props.field.min && index < this.props.field.min) error = true;
                    fieldsValues[name] = value;
                } catch (e) {
                    error = true;
                }
            });
            values.push({
                id: item.id,
                values: fieldsValues
            });
        });
        if (error) {
            if (!disableErrorDisplay) this.displayError();
            throw new Error();
        }
        return values.filter(Boolean);
    }

    public displayError(): void {
        //Error
    }

}
