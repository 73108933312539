import React = require("react");
import { FooterTexts } from "../partials/Footer";
import { HeaderTexts } from "../partials/Header";
import Section from "../partials/Section";
import TemplateMain from "../templates/TemplateMain";
import UserI from "../types/User";
import ScrollTo from "../utils/ScrollTo";

export interface IndexTexts {
    page: string | unknown;
    slogan: string | unknown;
    description: string | unknown;
    button: string | unknown;
    noSignUp: string | unknown;
    features: {
        editable: {
            title: string | unknown;
            description: string | unknown;
        };
        permissions: {
            title: string | unknown;
            description: string | unknown;
        };
        communication: {
            title: string | unknown;
            description: string | unknown;
        };
        custom: {
            title: string | unknown;
            description: string | unknown;
        };
    };
}

export interface IndexProps {
    dark: boolean;
    texts: {
        header: HeaderTexts;
        footer: FooterTexts;
        index: IndexTexts;
    };
    user?: UserI;
    page: string;
    language: string;
    path: string;
}

interface ComponentState {
    apiResponse: string;
}

export default class Index extends React.Component<IndexProps, ComponentState> {

    constructor(props: IndexProps) {
        super(props);
        this.state = {
            apiResponse: ""
        };
    }

    render(): React.ReactNode {
        const { texts } = this.props;
        return (
            <TemplateMain {...this.props}>
                <Section style={{ marginTop: "-6rem", paddingTop: "65px" }}>
                    <div className="text-center d-flex flex-column align-items-center" style={{ maxHeight: "calc(100vh - 190px)", flexFlow: "column" }}>
                        <img data-aos="fade-in" src="/assets/images/logos/xebot_alpha.png" className="logo-alpha img-circle p-0 mb-3    " style={{ maxHeight: "50vh", width: "auto", overflow: "auto", flex: 2 }} />

                        <h2 className="mb-3" data-aos="fade-in" data-aos-delay="200" data-aos-anchor="#logo-icon-main">
                            {texts.index.slogan}
                        </h2>
                        <h6 data-aos="fade-in" data-aos-delay="500" data-aos-anchor="#logo-icon-main">
                            {texts.index.description}
                        </h6>

                        <a id="add_xebot" href="/setup" className="btn btn-primary btn-glow-animate mt-4" data-aos="fade-in" data-aos-delay="700" data-aos-anchor="#logo-icon-main">
                            {texts.index.button}
                        </a>
                        <small className="pt-2 d-block text-center" data-aos="fade-in" data-aos-delay="900" data-aos-anchor="#logo-icon-main">
                            {texts.index.noSignUp}
                        </small>
                        <ScrollTo id="scroll-to-content" element="scroll-to-content" className="mt-4" />
                    </div>
                </Section>
                <Section>
                    <section className="container" data-aos="fade-up">
                        <div className="row align-items-center">
                            <div className="col-8 col-md-4 mx-auto">
                                <img className="rounded" src="/assets/images/tutorial/index/edition.png" style={{ width: "100%", height: "auto" }} />
                            </div>
                            <div className="col-md p-5">
                                <h2 className="mb-4">{texts.index.features.editable.title}</h2>
                                <p>{texts.index.features.editable.description}</p>
                            </div>
                        </div>
                    </section>

                    <section className="container mt-5" data-aos="fade-up">
                        <div className="row align-items-center flex-md-row-reverse">
                            <div className="col-8 col-md-4 mx-auto">
                                <img className="rounded" src="/assets/images/tutorial/index/permissions.png" style={{ width: "100%", height: "auto" }} />
                            </div>
                            <div className="col-md p-5">
                                <h2 className="mb-4">{texts.index.features.permissions.title}</h2>
                                <p>{texts.index.features.permissions.description}</p>
                            </div>
                        </div>
                    </section>

                    <section className="container" data-aos="fade-up">
                        <div className="row align-items-center">
                            <div className="col-8 col-md-4 mx-auto">
                                <img className="rounded" src="/assets/images/tutorial/index/communication.png" style={{ width: "100%", height: "auto" }} />
                            </div>
                            <div className="col-md p-5">
                                <h2 className="mb-4">{texts.index.features.communication.title}</h2>
                                <p>{texts.index.features.communication.description}</p>
                            </div>
                        </div>
                    </section>

                    <section className="container mt-5" data-aos="fade-up">
                        <div className="row align-items-center flex-md-row-reverse">
                            <div className="col-8 col-md-4 mx-auto">
                                <img className="rounded" src="/assets/images/tutorial/index/design.gif" style={{ width: "100%", height: "auto" }} />
                            </div>
                            <div className="col-md p-5">
                                <h2 className="mb-4">{texts.index.features.custom.title}</h2>
                                <p>{texts.index.features.custom.description}</p>
                            </div>
                        </div>
                    </section>
                </Section>
            </TemplateMain>
        );
    }

}
