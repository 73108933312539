import React = require("react");
import CommandI, { Categories } from "../types/Command";
import UserGuildI from "../types/UserGuild";

export interface SetupMenuTexts {
    changeGuild: string | unknown;
    global: {
        title: string | unknown;
        dashboard: string | unknown;
        permissions: string | unknown;
    };
    commands: {
        title: string | unknown;
    };
}

export interface SetupMenuProps {
    texts: SetupMenuTexts;
    guild: UserGuildI;
    command?: CommandI;
}

export interface SetupMenuState {
    active: boolean;
}

export default class SetupMenu extends React.Component<SetupMenuProps, SetupMenuState> {

    constructor(props: SetupMenuProps) {
        super(props);
        this.state = {
            active: true
        };
    }

    toggleMenu(): void {
        this.setState({ active: !this.state.active });
    }

    categoriesSorter(a: [string, CommandI[]], b: [string, CommandI[]]): number {
        const sorted = Object.keys(Categories);
        const ia = sorted.indexOf(a[1][0].categoryEN);
        const ib = sorted.indexOf(b[1][0].categoryEN);
        return ia - ib;
    }

    render(): React.ReactNode {
        const { texts, guild, command } = this.props;
        const categories: Record<string, Array<CommandI>> = {};
        guild.commands.forEach((c) => {
            if (!c.config || (!c.config.use && !c.config.setup)) return;
            if (!categories[c.category as string]) categories[c.category as string] = [];
            categories[c.category as string].push(c);
        });
        return (
            <span className={`setup-menu ${this.state.active ? "active" : ""}`}>
                <span className="setup-menu-toggle" onClick={this.toggleMenu.bind(this)}>
                    <i className="fas fa-chevron-right" aria-hidden="true"></i>
                </span>
                <div className="setup-menu-menu p-3 pt-5">
                    <div className="text-center">
                        <img className="rounded-circle m-2 mx-auto" src={guild.iconURL} height="70" width="70" />
                        <h1 className="font-weight-bold text-wrap" style={{ wordBreak: "break-word", overflowWrap: "break-word" }}>
                            {guild.name}
                        </h1>
                        <a className="unstyled-link small" href="/setup">
                            {texts.changeGuild}
                        </a>
                    </div>
                    <hr />
                    <div>
                        <h5>{texts.global.title}</h5>
                        <nav className="navbar-nav">
                            <a className="p-2 nav-link unstyled-link" href={`/setup/${guild.id}/dashboard`}>
                                {texts.global.dashboard}
                            </a>
                            <a className="p-2 nav-link unstyled-link" href={`/setup/${guild.id}/permissions`}>
                                {texts.global.permissions}
                            </a>
                        </nav>
                    </div>
                    <hr />
                    <div>
                        <h5>{texts.commands.title}</h5>
                        <nav>
                            {Object.entries(categories)
                                .sort(this.categoriesSorter)
                                .map(([category, commands], i) => {
                                    const activeCategory = command && command.categoryEN === commands[0].categoryEN;
                                    return (
                                        <div key={i} className="command-category" style={{ borderColor: Categories[commands[0].categoryEN] }}>
                                            <a className={`unstyled-link nav-link dropdown-toggle command-category-name ${activeCategory ? "" : "collapsed"}`} href={`#${category}subMenu`} data-toggle="collapse" aria-expanded={activeCategory ? "true" : "false"}>
                                                {category}
                                            </a>
                                            <ul className={`navbar-nav collapse command-category-list ${activeCategory ? "show" : ""}`} id={`${category}subMenu`}>
                                                {commands.map((c, index) => (
                                                    <li key={index} className="nav-item">
                                                        <a className={`unstyled-link nav-link command-category-command ${!c.authorized ? "disabled" : ""}`} href={`/setup/${guild.id}/command/${c.link}`}>
                                                            {c.name}
                                                        </a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    );
                                })}
                        </nav>
                    </div>
                </div>
            </span>
        );
    }

}
