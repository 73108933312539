import React = require("react");

export enum ToolTipPosition {
    TOP_LEFT = "tooltip-top-left",
    TOP_CENTER = "tooltip-top-center",
    TOP_RIGHT = "tooltip-top-right",
    CENTER_CENTER = "tooltip-center-center"
}

export interface ToolTipContainerProps {
    className?: string;
}

export interface ToolTipContainerState {
    active: boolean;
}

export interface ToolTipProps {
    position?: ToolTipPosition;
}

export class ToolTip extends React.Component<ToolTipProps> {

    render(): React.ReactNode {
        const position = this.props.position || ToolTipPosition.TOP_CENTER;
        return <span className={`tooltip-content ${position}`}>{this.props.children}</span>;
    }

}

export default class ToolTipContainer extends React.Component<ToolTipContainerProps, ToolTipContainerState> {

    constructor(props: ToolTipContainerProps) {
        super(props);
        this.state = {
            active: false
        };
    }

    render(): React.ReactNode {
        return (
            <span className={`tooltip-container ${this.state.active ? "tooltip-active" : ""} ${this.props.className || ""}`} onPointerEnter={this.enableToolTip.bind(this)} onPointerLeave={this.disableToolTip.bind(this)}>
                {this.props.children}
            </span>
        );
    }

    enableToolTip(): void {
        this.setState({
            active: true
        });
    }

    disableToolTip(): void {
        this.setState({
            active: false
        });
    }

}
