import React, { useEffect, useState } from "react";
import AnimateHeight from "react-animate-height";

export type TabData = {
    defaultTab: string;
    setActiveTab: (name: string) => void;
    registerTab: (name: string, setActive: React.Dispatch<React.SetStateAction<boolean>>) => boolean;
};

export default function TabContainer(props: { children: (tabData: TabData) => React.ReactNode; defaultTab?: string }): React.ReactElement {
    const [activeTab, setActiveTab] = useState(props.defaultTab);
    const [tabs, setTabs] = useState<Array<{ name: string; setActive: React.Dispatch<React.SetStateAction<boolean>> }>>([]);
    const unsavedTabs: Array<{ name: string; setActive: React.Dispatch<React.SetStateAction<boolean>> }> = [];
    const register = (name: string, setActive: React.Dispatch<React.SetStateAction<boolean>>): boolean => {
        unsavedTabs.push({ name, setActive });
        return name === activeTab;
    };
    const externalSetActiveTab = (name: string): void => {
        if (unsavedTabs.length === 0) unsavedTabs.push(...tabs);
        else setTabs(unsavedTabs);
        if (name === activeTab) return;
        if (!tabs.find(t => t.name === name)) return;
        unsavedTabs.forEach((t) => {
            t.setActive(false);
        });
        setTimeout(() => {
            unsavedTabs.forEach((t) => {
                if (t.name === name) t.setActive(true);
            });
        }, 200);
        setActiveTab(name);
    };
    return <div className="custom-tab-container">{props.children({ setActiveTab: externalSetActiveTab, registerTab: register, defaultTab: props.defaultTab })}</div>;
}

export function Tabs(props: { children: React.ReactNode }): React.ReactElement {
    return <div className="custom-tab-tabs">{props.children}</div>;
}
export function TabsContent(props: { children: React.ReactNode }): React.ReactElement {
    return <div className="custom-tab-contents">{props.children}</div>;
}

export function Tab(props: { children: React.ReactNode; name: string; tabData: TabData; className?: string }): React.ReactElement {
    const [active, setActive] = useState(props.tabData.defaultTab === props.name);
    const externalSetActive = (value: boolean) => {
        setActive(value);
    };
    useEffect(() => {
        setActive(props.tabData.registerTab(props.name, externalSetActive));
    }, []);
    const setAsActive = () => {
        props.tabData.setActiveTab(props.name);
    };
    return (
        <div className={`custom-tab ${active ? "custom-tab-active" : ""} ${props.className || ""}`} onClick={setAsActive}>
            {props.children}
        </div>
    );
}

export function TabContent(props: { children: React.ReactElement; name: string; tabData: TabData; className?: string }): React.ReactElement {
    const [active, setActive] = useState(props.tabData.defaultTab === props.name);
    const externalSetActive = (value: boolean) => {
        setActive(value);
    };
    useEffect(() => {
        setActive(props.tabData.registerTab(props.name, externalSetActive));
    }, []);
    return (
        <div className={`custom-tab-content ${active ? "custom-tab-content-active" : ""} ${props.className || ""}`}>
            <AnimateHeight translate="yes" duration={1000} height={active ? "auto" : 0}>
                {props.children}
            </AnimateHeight>
        </div>
    );
}
