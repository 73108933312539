import React = require("react");
import ChannelI from "../../types/Channel";
import CommandI, { FieldI } from "../../types/Command";
import RoleI from "../../types/Role";
import UserGuildI from "../../types/UserGuild";
import EmojiPicker from "../../utils/EmojiPicker";
import { CompositeDecorator, ContentBlock, ContentState, convertFromRaw, convertToRaw, DraftEntityMutability, DraftInlineStyleType, Editor, EditorState, Modifier, RawDraftContentBlock, RawDraftContentState, RichUtils } from "draft-js";
import StayInScreen from "../../utils/StayInScreen";
import { UIField, UIFieldProps } from "./Field";
import AnimateHeight from "react-animate-height";

export interface TextFieldProps extends UIFieldProps {
    command: CommandI;
    guild: UserGuildI;
    field: FieldI;
    value?: {
        raw: unknown;
        converted: string;
    };
    emojiPicker: React.RefObject<EmojiPicker>;
}

export interface TextFieldState {
    charCount: number;
    editorState: EditorState;
    mounted: boolean;
    showURLInput: "url" | "both";
    url: string;
    urlText: string;
    value: {
        raw: RawDraftContentState;
        converted: string;
    };
    invalid: boolean;
}

export default class TextField extends React.Component<TextFieldProps, TextFieldState> implements UIField {

    editor: React.RefObject<HTMLDivElement> = React.createRef();
    url: React.RefObject<HTMLInputElement> = React.createRef();
    urlText: React.RefObject<HTMLInputElement> = React.createRef();

    Link = (props: { contentState: ContentState; entityKey: string; children: React.ReactChildren }): React.ReactNode => {
        const { url } = props.contentState.getEntity(props.entityKey).getData();
        return (
            <a href={url} style={{ color: "#007bff" }}>
                {props.children}
            </a>
        );
    };

    Emoji = (props: { contentState: ContentState; entityKey: string; offsetKey: unknown; children: React.ReactChildren }): React.ReactNode => {
        const { src, alt } = props.contentState.getEntity(props.entityKey).getData();
        return (
            <span data-offset-key={props.offsetKey} className="emoji" data-alt={alt} style={{ backgroundImage: `url(${src})`, backgroundPositionX: "center", backgroundPositionY: "center", backgroundRepeat: "no-repeat", backgroundSize: "contain" }}>
                {props.children}
            </span>
        );
    };

    Mention = (props: { contentState: ContentState; entityKey: string; offsetKey: unknown; children: React.ReactChildren }): React.ReactNode => {
        const { color } = props.contentState.getEntity(props.entityKey).getData();
        return (
            <span data-offset-key={props.offsetKey} style={{ color: color, backgroundColor: `${color}4c`, borderRadius: "2px", padding: "0px 2px" }}>
                {props.children}
            </span>
        );
    };

    decorator = new CompositeDecorator([
        {
            strategy: this.findLinkEntities,
            component: this.Link
        },
        {
            strategy: this.findEmojiEntities,
            component: this.Emoji
        },
        {
            strategy: this.findMentionEntities,
            component: this.Mention
        }
    ]);
    constructor(props: TextFieldProps) {
        super(props);

        this.state = {
            mounted: false,
            charCount: 0,
            editorState: EditorState.createEmpty(this.decorator),
            showURLInput: undefined,
            url: "",
            urlText: "",
            value: undefined,
            invalid: false
        };
    }

    render(): React.ReactNode {
        const { field, guild, texts } = this.props;

        return (
            <div>
                <div className={`text-editor ${this.state.invalid ? "field-invalid" : ""}`}>
                    <div className="text-editor-header d-flex flex-wrap">
                        {/* Undo and Redo */}
                        <div className="btn-group mt-2" role="group" aria-label="Undo and Redo group">
                            <button className="btn btn-secondary btn-sm" title="Ctrl + Z" data-command="undo" onClick={this.executeEditorAction.bind(this)}>
                                <i className="fas fa-undo"></i>
                            </button>
                            <button className="btn btn-secondary btn-sm" title="Ctrl + Y" data-command="redo" onClick={this.executeEditorAction.bind(this)}>
                                <i className="fas fa-redo"></i>
                            </button>
                        </div>
                        {/* Bold, Italic, Underline and StrikeThrough */}
                        <div className="btn-group mt-2" role="group" aria-label="Undo and Redo group">
                            <button className="btn btn-secondary btn-sm" title="Ctrl + B" data-command="bold" onClick={this.executeEditorAction.bind(this)}>
                                <i className="fas fa-bold"></i>
                            </button>
                            <button className="btn btn-secondary btn-sm" title="Ctrl + I" data-command="italic" onClick={this.executeEditorAction.bind(this)}>
                                <i className="fas fa-italic"></i>
                            </button>
                            <button className="btn btn-secondary btn-sm" title="Ctrl + U" data-command="underline" onClick={this.executeEditorAction.bind(this)}>
                                <i className="fas fa-underline"></i>
                            </button>
                            <button className="btn btn-secondary btn-sm" data-command="strikeThrough" onClick={this.executeEditorAction.bind(this)}>
                                <i className="fas fa-strikethrough"></i>
                            </button>
                            <button className="btn btn-secondary btn-sm" data-command="removeFormat" onClick={this.executeEditorAction.bind(this)}>
                                <i className="fas fa-remove-format"></i>
                            </button>
                        </div>
                        {/* Links */}
                        <div className="btn-group mt-2" role="group" aria-label="Undo and Redo group">
                            <button className="btn btn-secondary btn-sm" data-command="createlink" onMouseDown={this.promptForLink.bind(this)}>
                                <i className="fas fa-link"></i>
                            </button>
                            <button className="btn btn-secondary btn-sm" data-command="unlink" onMouseDown={this.removeLink.bind(this)}>
                                <i className="fas fa-unlink"></i>
                            </button>
                            {this.state.showURLInput && (
                                <StayInScreen className="text-editor-link-prompt">
                                    <div className="text-editor-link-prompt-fields">
                                        <input placeholder="https://" onChange={this.onURLChange.bind(this)} ref={this.url} type="text" value={this.state.url} onKeyDown={this.onLinkInputKeyDown.bind(this)} />
                                        {this.state.showURLInput === "both" && <input placeholder="My link" onChange={this.onURLTextChange.bind(this)} ref={this.urlText} type="text" onKeyDown={this.onLinkTextInputKeyDown.bind(this)} />}
                                    </div>
                                    <div className="d-flex justify-content-around">
                                        <button className="text-editor-link-prompt-button btn btn-sm btn-danger" onMouseDown={this.cancelLink.bind(this)}>
                                            Cancel
                                        </button>
                                        <button className="text-editor-link-prompt-button btn btn-sm btn-success" onMouseDown={this.confirmLink.bind(this)}>
                                            Confirm
                                        </button>
                                    </div>
                                </StayInScreen>
                            )}
                        </div>
                        {/* Mentions */}
                        <div className="btn-group mt-2" role="group" aria-label="Mentions">
                            <div className="dropdown dropdown-hover p-0 btn btn-secondary btn-sm">
                                <button className="dropdown-toggle btn btn-secondary btn-sm" type="button" id="dropdownMentionRoleMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ height: "100%", background: "none", border: "none", color: "inherit" }}>
                                    <i className="fas fa-at"></i>
                                </button>
                                <ul className="dropdown-menu p-2" aria-labelledby="dropdownMentionRoleMenuButton">
                                    {guild.roles.map((role) => (
                                        <li key={role.id} onClick={this.addRole.bind(this)} data-role={JSON.stringify(role)} style={{ color: role.color }} className="small pt-1 pb-1">
                                            @{role.name}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="dropdown dropdown-hover p-0 btn btn-secondary btn-sm">
                                <button className="dropdown-toggle btn btn-secondary btn-sm" type="button" id="dropdownMentionChannelMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ height: "100%", background: "none", border: "none", color: "inherit" }}>
                                    <i className="fas fa-hashtag"></i>
                                </button>
                                <ul className="dropdown-menu p-2" aria-labelledby="dropdownMentionChannelMenuButton">
                                    {guild.channels.map((channel) => {
                                        if (channel.type === "GUILD_CATEGORY") {
                                            return (
                                                <li key={channel.id} style={{ textTransform: "uppercase", color: "#b2b4b7" }} value={channel.id} className="disabled small pt-1 pb-1">
                                                    {channel.name}
                                                </li>
                                            );
                                        } else {
                                            return (
                                                <li key={channel.id} style={{ color: "#7289da" }} value={channel.id} data-channel={JSON.stringify(channel)} onClick={this.addChannel.bind(this)} className="small pt-1 pb-1">
                                                    {channel.name}
                                                </li>
                                            );
                                        }
                                    })}
                                </ul>
                            </div>
                        </div>
                        {/* Emoji */}
                        <div className="btn-group mt-2" role="group" aria-label="Emoji group">
                            <button className="btn btn-secondary btn-sm" onClick={this.selectEmoji.bind(this)}>
                                <i className="fas fa-grin-alt"></i>
                            </button>
                        </div>
                    </div>
                    {/* <ContentEditable innerRef={this.editor} className="text-editor-content" onChange={this.handleChange.bind(this)} onKeyDown={this.handleKeyDown.bind(this)} html={this.state.html} /> */}
                    <div className="text-editor-content">{this.state.mounted && <Editor editorState={this.state.editorState} onChange={this.editorChange.bind(this)} handleKeyCommand={this.editorKeyCommand.bind(this)} />}</div>
                    {field.maxLength && (
                        <div className={`text-editor-charcount small ${this.state.charCount > field.maxLength ? "field-error" : "text-muted"}`}>
                            {this.state.charCount} / {field.maxLength}
                        </div>
                    )}
                </div>
                <AnimateHeight height={this.state.invalid ? "auto" : 0}>
                    <small className="field-error">{field.maxLength && this.state.charCount > field.maxLength ? texts.error.textTooLong : texts.error.requiredValue}</small>
                </AnimateHeight>
            </div>
        );
    }

    componentDidMount(): void {
        let editorState = EditorState.createEmpty(this.decorator);
        if (this.props.value) {
            if (this.props.value.raw) editorState = EditorState.createWithContent(convertFromRaw(this.props.value.raw as RawDraftContentState), this.decorator);
            else editorState = EditorState.createWithContent(ContentState.createFromText((this.props.value as unknown) as string), this.decorator);
        }
        this.editorChange(editorState);
        this.setState({
            editorState: editorState,
            mounted: true
        });
    }

    lastValue: string;

    editorChange(editorState: EditorState): void {
        const value = {
            raw: convertToRaw(editorState.getCurrentContent()),
            converted: this.getDiscordEquivalent(convertToRaw(editorState.getCurrentContent()))
        };
        this.setState({
            value: value,
            charCount: value.converted.length,
            editorState: editorState
        });
        if (this.lastValue !== value.converted && this.checkValue(value)) this.triggerOnChange(value);
        this.lastValue = value.converted;
    }

    editorKeyCommand(command: string, editorState: EditorState): string {
        const allowedCommands: Array<string> = ["bold", "italic", "underline", "redo"];
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (allowedCommands.includes(command) && newState) {
            this.editorChange(newState);
            return "handled";
        } else {
            //console.log("command not handled", command);
            return "not-handled";
        }
    }

    executeEditorAction(event: MouseEvent): void {
        const command = (event.currentTarget as HTMLButtonElement).dataset.command;
        const { editorState } = this.state;
        switch (command) {
            case "undo":
                this.editorChange(EditorState.undo(editorState));
                break;
            case "redo":
                this.editorChange(EditorState.redo(editorState));
                break;
            case "removeFormat":
                const currentStyles = editorState.getCurrentInlineStyle();
                let contentState = editorState.getCurrentContent();
                currentStyles.forEach((style) => {
                    contentState = Modifier.removeInlineStyle(contentState, editorState.getSelection(), style);
                });
                const newEditorState = EditorState.push(editorState, contentState, "change-inline-style");
                const newContentState = RichUtils.tryToRemoveBlockStyle(newEditorState);
                if (newContentState) this.editorChange(EditorState.push(newEditorState, newContentState, "change-block-type"));
                else this.editorChange(newEditorState);
                break;
            default:
                this.editorChange(RichUtils.toggleInlineStyle(editorState, command.toUpperCase()));
        }
    }

    onURLChange = (e: React.ChangeEvent): void => this.setState({ url: (e.target as HTMLInputElement).value });
    onURLTextChange = (e: React.ChangeEvent): void => this.setState({ urlText: (e.target as HTMLInputElement).value });

    promptForLink(): void {
        const { editorState } = this.state;
        const selection = editorState.getSelection();
        const contentState = editorState.getCurrentContent();
        const startKey = editorState.getSelection().getStartKey();
        const startOffset = editorState.getSelection().getStartOffset();
        const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
        const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);

        let url = "";
        if (linkKey) {
            const linkInstance = contentState.getEntity(linkKey);
            url = linkInstance.getData().url;
        }

        this.setState({
            showURLInput: selection.isCollapsed() && !url ? "both" : "url",
            url: url,
            urlText: undefined
        });
    }

    cancelLink(): void {
        this.setState({
            showURLInput: undefined,
            url: "",
            urlText: ""
        });
    }

    confirmLink(event?: React.KeyboardEvent | React.MouseEvent): void {
        if (event) event.stopPropagation();
        const { editorState, url, urlText } = this.state;
        if (!url) return;
        let transformedUrl;
        if (!url.startsWith("http://") && !url.startsWith("https://")) transformedUrl = `https://${url}`;
        else transformedUrl = url;

        const selection = editorState.getSelection();
        const contentState = editorState.getCurrentContent();
        const startKey = editorState.getSelection().getStartKey();
        const startOffset = editorState.getSelection().getStartOffset();
        const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
        const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);

        if (linkKey) {
            //Update
            const newEditorState = EditorState.push(
                editorState,
                contentState.mergeEntityData(linkKey, {
                    url: transformedUrl
                }),
                "change-block-data"
            );
            this.editorChange(newEditorState);
        } else {
            //Insert// Get block for current selection
            const anchorKey = selection.getAnchorKey();
            const currentContent = editorState.getCurrentContent();
            const currentBlock = currentContent.getBlockForKey(anchorKey);

            //Then based on the docs for SelectionState -
            const start = selection.getStartOffset();
            const end = selection.getEndOffset();
            const selectedText = currentBlock.getText().slice(start, end);

            this.insertEntityOnSelection("LINK", "MUTABLE", { url: transformedUrl }, urlText || selectedText);
        }

        this.setState({
            showURLInput: undefined,
            url: "",
            urlText: ""
        });
    }

    onLinkInputKeyDown(e: KeyboardEvent | React.KeyboardEvent<HTMLInputElement>): void {
        if (e.key === "Enter") {
            if (this.state.showURLInput === "both" && this.urlText.current.value.length === 0) return;
            this.confirmLink();
        }
    }

    onLinkTextInputKeyDown(e: KeyboardEvent | React.KeyboardEvent<HTMLInputElement>): void {
        if (e.key === "Enter") {
            if (this.state.showURLInput === "both" && (this.url.current.value.length === 0 || this.urlText.current.value.length === 0)) return;
            this.confirmLink();
        }
    }

    removeLink(e: MouseEvent | React.MouseEvent<HTMLButtonElement>): void {
        e.preventDefault();
        const { editorState } = this.state;

        const selection = editorState.getSelection();
        const contentState = editorState.getCurrentContent();
        const startKey = selection.getStartKey();
        const startOffset = editorState.getSelection().getStartOffset();
        const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
        const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);

        if (!linkKey) return;
        const linkInstance = contentState.getEntity(linkKey);
        if (linkInstance.getType() !== "LINK") return;

        let entitySelection = null;

        blockWithLinkAtBeginning.findEntityRanges(
            (character) => character.getEntity() === linkKey,
            (start, end) => {
                entitySelection = selection.merge({
                    anchorOffset: start,
                    focusOffset: end
                });
            }
        );

        const newContentState = Modifier.applyEntity(contentState, entitySelection, null);

        const newEditorState = EditorState.push(editorState, newContentState, "apply-entity");
        this.editorChange(newEditorState);
    }

    insertEntityOnSelection(entityName: string, entityType: DraftEntityMutability, data: unknown, text?: string): void {
        let { editorState } = this.state;
        let selection = editorState.getSelection();
        //insert
        let newEditorState: EditorState;
        let textWithEntity;
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(entityName, entityType, data);
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        if (selection.isCollapsed()) {
            textWithEntity = Modifier.insertText(contentState, selection, text, null, entityKey);
            newEditorState = EditorState.push(editorState, textWithEntity, "insert-characters");
        } else {
            textWithEntity = Modifier.replaceText(contentState, selection, text, null, entityKey);
            newEditorState = EditorState.push(editorState, textWithEntity, "insert-characters");
        }
        selection = newEditorState.getSelection();
        const collapsed = selection.merge({
            anchorOffset: selection.getEndOffset(),
            focusOffset: selection.getEndOffset()
        });

        newEditorState = EditorState.forceSelection(newEditorState, collapsed);

        this.setState(
            {
                editorState: newEditorState
            },
            () => {
                setTimeout(() => {
                    editorState = this.state.editorState;
                    selection = editorState.getSelection();
                    const cs = Modifier.insertText(editorState.getCurrentContent(), selection, " ");
                    newEditorState = EditorState.push(editorState, cs, "insert-characters");
                    this.setState({ editorState: newEditorState });
                }, 10);
            }
        );
    }

    findLinkEntities(contentBlock: ContentBlock, callback: (start: number, end: number) => void, contentState: ContentState): void {
        contentBlock.findEntityRanges((character) => {
            const entityKey = character.getEntity();
            return entityKey !== null && contentState.getEntity(entityKey).getType() === "LINK";
        }, callback);
    }
    findEmojiEntities(contentBlock: ContentBlock, callback: (start: number, end: number) => void, contentState: ContentState): void {
        contentBlock.findEntityRanges((character) => {
            const entityKey = character.getEntity();
            return entityKey !== null && contentState.getEntity(entityKey).getType() === "EMOJI";
        }, callback);
    }
    findMentionEntities(contentBlock: ContentBlock, callback: (start: number, end: number) => void, contentState: ContentState): void {
        contentBlock.findEntityRanges((character) => {
            const entityKey = character.getEntity();
            return entityKey !== null && contentState.getEntity(entityKey).getType() === "MENTION";
        }, callback);
    }

    async selectEmoji(): Promise<void> {
        this.props.emojiPicker.current.show();
        try {
            const emoji = await this.props.emojiPicker.current.waitSelection();
            this.insertEntityOnSelection("EMOJI", "IMMUTABLE", emoji, " ");
        } catch (e) {
            console.log(e);
        }
        this.props.emojiPicker.current.hide();
    }

    addRole(event: React.MouseEvent): void {
        const role = JSON.parse((event.currentTarget as HTMLLIElement).dataset.role) as RoleI;
        this.insertEntityOnSelection(
            "MENTION",
            "IMMUTABLE",
            {
                type: "role",
                name: `@${role.name}`,
                color: role.color,
                data: role
            },
            `@${role.name}`
        );
    }

    addChannel(event: React.MouseEvent): void {
        const channel = JSON.parse((event.currentTarget as HTMLLIElement).dataset.channel) as ChannelI;
        this.insertEntityOnSelection(
            "MENTION",
            "IMMUTABLE",
            {
                type: "channel",
                name: channel.name,
                color: "#7289da",
                data: channel
            },
            channel.name
        );
    }

    getDiscordEquivalent(rawValue: RawDraftContentState): string {
        const LINE_BREAK = "%0D%0A";
        const entityMap = rawValue.entityMap;

        function getBlockValue(block: RawDraftContentBlock): string {
            const currentStyles: Array<DraftInlineStyleType> = [];
            const currentEntities: Array<string> = [];
            function getStringForStyle(s: DraftInlineStyleType): string {
                switch (s) {
                    case "BOLD":
                        return "**";
                    case "CODE":
                        return "`";
                    case "ITALIC":
                        return "_";
                    case "STRIKETHROUGH":
                        return "~~";
                    case "UNDERLINE":
                        return "__";
                }
            }
            function checkStyles(position: number): string {
                const newStyles = block.inlineStyleRanges.filter((s) => s.offset === position);
                const oldStyles = block.inlineStyleRanges.filter((s) => s.offset + s.length === position);
                let styles = "";
                const newCurrentStyles = [...currentStyles];
                currentStyles.forEach((style) => {
                    if (oldStyles.find((s) => s.style === style)) {
                        styles += getStringForStyle(style);
                        newCurrentStyles.splice(newCurrentStyles.indexOf(style), 1);
                    }
                });
                currentStyles.splice(0, currentStyles.length);
                currentStyles.push(...newCurrentStyles);
                newStyles.forEach((style) => {
                    currentStyles.unshift(style.style);
                    styles += getStringForStyle(style.style);
                });
                return styles;
            }
            function checkEntities(position: number): { result: string; supressChar: boolean; delayStyle: boolean } {
                const newEntities = block.entityRanges.filter((s) => s.offset === position);
                const oldEntities = block.entityRanges.filter((s) => s.offset + s.length === position);
                let entities = "";
                let supressChar = false;
                oldEntities.forEach((entityData) => {
                    const entity = entityMap[entityData.key];
                    switch (entity.type) {
                        case "LINK":
                            entities += `](${entity.data.url})`;
                            currentEntities.splice(currentEntities.indexOf("LINK"), 1);
                            break;
                        case "MENTION":
                            currentEntities.splice(currentEntities.indexOf("MENTION"), 1);
                            supressChar = true;
                            break;
                    }
                });
                if (currentEntities.find((e) => e === "MENTION")) supressChar = true;
                newEntities.forEach((entityData) => {
                    const entity = entityMap[entityData.key];
                    switch (entity.type) {
                        case "LINK":
                            entities += `[`;
                            currentEntities.push("LINK");
                            break;
                        case "MENTION":
                            entities += `<${entity.data.type === "role" ? "@&" : "#"}${entity.data.data.id}>`;
                            currentEntities.push("MENTION");
                            supressChar = true;
                            break;
                        case "EMOJI":
                            const emoji = entity.data.alt;
                            if (emoji.length > 10) entities += `<:${emoji}>`;
                            else entities += emoji;
                            supressChar = true;
                            break;
                    }
                });
                return {
                    result: entities,
                    supressChar: supressChar,
                    delayStyle: !!currentEntities.find((e) => e === "LINK")
                };
            }

            let result = "";
            let delayedStyles = "";
            let entitiesResult;
            let lastEntitiesResult;
            let styles: string;
            for (let i = 0; i <= block.text.length; i++) {
                styles = checkStyles(i);
                entitiesResult = checkEntities(i);

                if (lastEntitiesResult && lastEntitiesResult.delayStyle) {
                    if (entitiesResult && entitiesResult.delayStyle) {
                        delayedStyles += styles || "";
                        styles = "";
                    } else {
                        result += entitiesResult.result || "";
                        result += delayedStyles || "";
                        result += styles || "";
                        delayedStyles = "";
                    }
                } else {
                    result += styles || "";
                    result += entitiesResult.result || "";
                }

                //Ajout du caractère
                if (i < block.text.length && !entitiesResult.supressChar) result += block.text.charAt(i);

                lastEntitiesResult = entitiesResult;
            }
            return result;
        }

        return rawValue.blocks.map((x) => getBlockValue(x)).join(LINE_BREAK);
    }

    triggerOnChange(value: { raw: RawDraftContentState; converted: string }): void {
        if (this.props.onChange) {
            this.props.onChange({
                name: this.props.field.name,
                value: value
            });
        }
    }

    public checkValue(value?: { raw: RawDraftContentState; converted: string }): boolean {
        if (!value) value = this.state.value;
        const result = !((this.props.field.required && (!this.state.value || this.state.value.converted.length == 0)) || (this.props.field.maxLength && this.state.charCount > this.props.field.maxLength));
        if (result) {
            this.setState({
                invalid: false
            });
        }
        return result;
    }

    public getValue(
        disableErrorDisplay?: boolean
    ): {
        raw: RawDraftContentState;
        converted: string;
    } {
        if (!this.checkValue()) {
            if (!disableErrorDisplay) this.displayError();
            throw new Error();
        }
        return this.state.value;
    }

    public displayError(): void {
        this.setState({
            invalid: true
        });
    }

}
