import React = require("react");
import AnimateHeight from "react-animate-height";
import ChannelI from "../../types/Channel";
import CommandI, { FieldI } from "../../types/Command";
import UserGuildI from "../../types/UserGuild";
import { UIField, UIFieldProps } from "./Field";

export interface ChannelFieldProps extends UIFieldProps {
    command: CommandI;
    guild: UserGuildI;
    field: FieldI;
    value?: ChannelI;
}

export interface ChannelFieldState {
    value: string;
    invalid: boolean;
}

export default class ChannelField extends React.Component<ChannelFieldProps, ChannelFieldState> implements UIField {

    constructor(props: ChannelFieldProps) {
        super(props);
        this.state = {
            value: props.value ? props.value.id : (props.field.default as string),
            invalid: false
        };
    }

    render(): React.ReactNode {
        const { guild, field, texts } = this.props;
        return (
            <div>
                <select className={`form-control ${this.state.invalid ? "field-invalid" : ""}`} defaultValue={this.state.value ? this.state.value : "none"} name={field.name as string} autoComplete="false" required={field.required} onChange={this.updateValue.bind(this)}>
                    <option disabled={!field.none} value="none">
                        -
                    </option>
                    {guild.channels.map((channel) => {
                        if (!channel.visible) return null;
                        if (channel.type === "GUILD_CATEGORY") {
                            return (
                                <option key={channel.id} style={{ textTransform: "uppercase" }} disabled={true} value={channel.id}>
                                    {channel.name}
                                </option>
                            );
                        } else {
                            return (
                                <option key={channel.id} style={{ color: "#7289da" }} value={channel.id}>
                                    {channel.name}
                                </option>
                            );
                        }
                    })}
                </select>
                <AnimateHeight height={this.state.invalid ? "auto" : 0}>
                    <small className="field-error">{texts.error.requiredValue}</small>
                </AnimateHeight>
            </div>
        );
    }

    updateValue(event: React.ChangeEvent): void {
        const value = (event.currentTarget as HTMLSelectElement).value;
        if (value) {
            this.setState({
                value: value
            });
            if (this.checkValue(value)) this.triggerOnChange(value);
        }
    }

    triggerOnChange(value: string): void {
        if (this.props.onChange) {
            this.props.onChange({
                name: this.props.field.name,
                value: value
            });
        }
    }

    public checkValue(value?: string): boolean {
        if (!value) value = this.state.value;
        const result = !(this.props.field.required && (!value || value.length == 0));
        if (result) {
            this.setState({
                invalid: false
            });
        }
        return result;
    }

    public getValue(): string {
        if (!this.checkValue()) {
            this.displayError();
            throw new Error();
        }
        return this.state.value;
    }

    public displayError(): void {
        this.setState({
            invalid: true
        });
    }

}
