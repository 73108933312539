export class Route {

    public path : string;
    public require: () => Promise<{default: any}>;

    constructor(path: string, require : () => Promise<{default: any}>) {
        this.path = path;
        this.require = require;
    }

}

export const routes: Array<Route> = [
    new Route('/', () => import("../client/pages/Index")),
    new Route('/features', () => import("../client/pages/Features")),
    new Route('/tutorial', () => import("../client/pages/Tutorial")),
    new Route('/setup', () => import("../client/pages/ServerList")),
    new Route('/error', () => import("../client/pages/Error")),
    new Route('/setup/*/dashboard', () => import("../client/pages/DashBoard")),
    new Route('/setup/*/permissions', () => import("../client/pages/Permissions")),
    new Route('/setup/*/command/*', () => import("../client/pages/UseCommand"))
];
