import superagent from "superagent";
import React = require("react");
import { FooterTexts } from "../partials/Footer";
import { HeaderTexts } from "../partials/Header";
import { SetupMenuTexts } from "../partials/SetupMenu";
import TemplateSetup from "../templates/TemplateSetup";
import CommandI from "../types/Command";
import RoleI from "../types/Role";
import UserI from "../types/User";
import UserGuildI from "../types/UserGuild";
import { useToasts } from "react-toast-notifications";
import { ModalElement, useModal } from "../utils/Modal";

export interface PermissionsTexts {
    page: string | unknown;
    notAnAdmin: string | unknown;
    notConnected: string | unknown;
    title: string | unknown;
    others: {
        description: string | unknown;
        tips: {
            title: string | unknown;
            description: string | unknown;
        };
    };
    admins: {
        description: string | unknown;
    };
    useNow: string | unknown;
    save: {
        title: string | unknown;
        success: string | unknown;
        fail: string | unknown;
    };
}

export interface PermissionsProps {
    dark: boolean;
    texts: {
        header: HeaderTexts;
        footer: FooterTexts;
        permissions: PermissionsTexts;
        setupMenu: SetupMenuTexts;
    };
    user?: UserI;
    page: string;
    language: string;
    path: string;
    guild: UserGuildI;
}

export default function Permissions(props: PermissionsProps): React.ReactElement {
    const { guild, texts } = props;

    const sortByLevel = (roles: Array<RoleI>, commands: Array<CommandI>): Array<{ level: string; commands: Array<CommandI>; roles: Array<RoleI> }> => {
        const result: Array<{ level: string; commands: Array<CommandI>; roles: Array<RoleI> }> = [];

        function getByLevel(level: string | number): { commands: Array<CommandI>; roles: Array<RoleI> } {
            const r: { commands: Array<CommandI>; roles: Array<RoleI> } = { commands: [], roles: [] };
            commands.forEach((command) => {
                if (command.permissions === level) r.commands.push(command);
            });
            roles.forEach((role) => {
                if (role.permissions === -1) {
                    if (level === "Admin" && role.admin) r.roles.push(role);
                    else if (level === -1 && !role.admin) r.roles.push(role);
                } else {
                    if (role.permissions === level) r.roles.push(role);
                }
            });
            return r;
        }

        let highestLevel = 9;
        commands.forEach((command) => {
            if (typeof command.permissions === "number" && command.permissions > highestLevel) highestLevel = command.permissions as number;
        });
        roles.forEach((role) => {
            if (typeof role.permissions === "number" && role.permissions > highestLevel) highestLevel = role.permissions as number;
        });
        highestLevel++;

        result.push({
            level: "All",
            ...getByLevel(-1)
        });
        for (let i = 1; i <= highestLevel; i++) {
            result.push({
                level: `${i}`,
                ...getByLevel(i)
            });
        }
        result.push({
            level: "Admin",
            ...getByLevel("Admin")
        });

        return result;
    };

    const { addToast } = useToasts();
    const { showModal } = useModal();
    const [lines, setLines] = React.useState(sortByLevel(guild.roles, guild.commands).reverse());
    const [selected, setSelected] = React.useState<RoleI>(undefined);

    let dragged: {
        role: RoleI;
        command: CommandI;
    } = {
        role: undefined,
        command: undefined
    };

    const sendUpdate = (item: RoleI | CommandI, type: "role" | "command"): void => {
        const data: { type: string; permissions: number; id: string } = {
            type: type,
            permissions: item.permissions as number,
            id: type === "command" ? (item as CommandI).table : (item as RoleI).id
        };
        superagent
            .post(`/setup/${guild.id}/permissions`)
            .send(data)
            .type("json")
            .accept("json")
            .then((response) => {
                if (!response.body) {
                    addToast(texts.permissions.save.fail, {
                        appearance: "error",
                        autoDismiss: true
                    });
                    return;
                }
                const result = (response.body as { result: string }).result;
                if (result !== "OK") {
                    addToast(texts.permissions.save.fail, {
                        appearance: "error",
                        autoDismiss: true
                    });
                } else {
                    addToast(texts.permissions.save.success, {
                        appearance: "success",
                        autoDismiss: true
                    });
                }
            })
            .catch((e) => {
                console.log(e);
                addToast(texts.permissions.save.fail, {
                    appearance: "error",
                    autoDismiss: true
                });
            });
    };

    const onDrop = (level: string, type: "role" | "command"): void => {
        if (level !== "All" && level !== "Admin") {
            if (dragged[type]) {
                dragged[type].permissions = parseInt(level);
                sendUpdate(dragged[type], type);
            }
            dragged = {
                role: undefined,
                command: undefined
            };
            setLines(sortByLevel(guild.roles, guild.commands).reverse());
        } else {
            dragged = {
                role: undefined,
                command: undefined
            };
        }
    };

    const onDragOver = (event: DragEvent): void => {
        event.preventDefault();
        event.stopPropagation();
    };

    const onDragStart = (item: RoleI & CommandI, type: "role" | "command"): void => {
        dragged[type] = item;
    };

    const select = (item: RoleI & CommandI, type: "role" | "command"): void => {
        if (type === "role") {
            if (selected === item) setSelected(undefined);
            else setSelected(item);
        }
        if (type === "command") {
            showModal(
                <>
                    <ModalElement.Title>{item.name}</ModalElement.Title>
                    <ModalElement.Description>
                        {item.aliases && item.aliases.map((alias, i) => <span key={i} className="badge badge-dark m-1">{alias}</span>)}
                        <p>{item.description}</p>
                    </ModalElement.Description>
                    {item.link && (
                        <ModalElement.Buttons>
                            <a className="btn btn-primary" href={`/setup/${guild.id}/command/${item.link}`}>
                                {texts.permissions.useNow}
                            </a>
                        </ModalElement.Buttons>
                    )}
                </>,
                { appearence: "none", dismissable: "all" }
            );
        }
    };

    let color = "danger";
    let key = 0;
    return (
        <TemplateSetup {...props}>
            {!guild.admin && (
                <div className="d-inline-block alert alert-warning" role="alert">
                    {texts.permissions.notAnAdmin}
                </div>
            )}

            <div className="mt-5">
                <p className="text-center h3">{texts.permissions.title}</p>
                <div className="text-center">{texts.permissions.others.description}</div>
                <div className="text-center">
                    <small className="text-muted font-italic">{texts.permissions.admins.description}</small>
                </div>
                <br />
                <div className="text-center">
                    <div className="font-weight-bold">{texts.permissions.others.tips.title}</div>
                    <p>
                        <small className="text-muted font-italic">{texts.permissions.others.tips.description}</small>
                    </p>
                </div>
            </div>

            <div className="table-responsive">
                <table className="table table-hover w-auto">
                    <thead className="thead-dark">
                        <tr style={{ backgroundColor: "#50aaff69" }}>
                            <th scope="col" className="align-middle" style={{ width: "10%" }}>
                                Permission
                                <br />
                                Level
                            </th>
                            <th scope="col" className="align-middle text-center" style={{ width: "45%" }}>
                                Command
                            </th>
                            <th scope="col" className="align-middle text-center" style={{ width: "45%" }}>
                                Role
                            </th>
                        </tr>
                    </thead>
                    <tbody id="tableLines">
                        {lines.map((line) => {
                            const draggable = line.level !== "Admin" && line.level !== "All" && guild.admin;
                            if (selected && line.roles.includes(selected)) color = "success";
                            return (
                                <tr key={key++} style={{ backgroundColor: line.level === "Admin" || line.level === "All" ? "#50aaff69" : "" }}>
                                    <td className="font-weight-bold">{line.level}</td>
                                    <td className={`col-command ${selected ? `bg-${color}` : ""}`} onDrop={onDrop.bind(this, line.level, "command")} onDragOver={onDragOver.bind(this)}>
                                        {line.commands.map((command) => (
                                            <div key={key++} className="command badge badge-dark mr-1 mb-1" draggable={draggable} onDragStart={onDragStart.bind(this, command, "command")} onClick={select.bind(this, command, "command")}>
                                                <span className="command-name">{command.name}</span>
                                                <span className="command-json d-none">{JSON.stringify(command)}</span>
                                            </div>
                                        ))}
                                    </td>
                                    <td className="col-role" onDrop={onDrop.bind(this, line.level, "role")} onDragOver={onDragOver.bind(this)}>
                                        {line.roles.map((role) => (
                                            <div key={key++} className={`role badge badge-dark mr-1 mb-1 ${draggable ? "draggable" : ""} ${selected === role ? "border border-success" : ""}`} style={{ color: role.color }} draggable={draggable} onDragStart={onDragStart.bind(this, role, "role")} onClick={select.bind(this, role, "role")}>
                                                <span className="role-name">{role.name}</span>
                                                <span className="role-json d-none">{JSON.stringify(role)}</span>
                                            </div>
                                        ))}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </TemplateSetup>
    );
}
