import React = require("react");

interface SectionDividerProps {
    reverse: boolean;
}

class SectionDivider extends React.Component<SectionDividerProps> {

    render() {
        return (
            <svg className={`divider ${this.props.reverse ? "divider-reverse" : ""}`} xmlns="http://www.w3.org/2000/svg" height="0.666667in" viewBox="0 0 1920 200" width="100%" preserveAspectRatio="none">
                <path
                    d="M 0.00,0.00
             C 0.00,0.00 0.00,156.00 0.00,156.00
             0.00,156.00 31.00,150.40 31.00,150.40
             31.00,150.40 91.00,138.42 91.00,138.42
             91.00,138.42 188.00,119.20 188.00,119.20
             188.00,119.20 323.00,92.60 323.00,92.60
             323.00,92.60 404.00,76.58 404.00,76.58
             404.00,76.58 432.00,71.18 432.00,71.18
             432.00,71.18 462.00,77.35 462.00,77.35
             462.00,77.35 525.00,92.12 525.00,92.12
             525.00,92.12 656.00,122.65 656.00,122.65
             656.00,122.65 719.00,137.42 719.00,137.42
             719.00,137.42 770.00,149.42 770.00,149.42
             770.00,149.42 794.00,153.82 794.00,153.82
             794.00,153.82 833.00,144.37 833.00,144.37
             833.00,144.37 915.00,123.88 915.00,123.88
             915.00,123.88 1213.00,49.63 1213.00,49.63
             1213.00,49.63 1312.00,25.13 1312.00,25.13
             1312.00,25.13 1344.00,17.12 1344.00,17.12
             1344.00,17.12 1362.00,14.04 1362.00,14.04
             1362.00,14.04 1378.00,15.83 1378.00,15.83
             1378.00,15.83 1406.00,18.83 1406.00,18.83
             1406.00,18.83 1527.00,31.84 1527.00,31.84
             1527.00,31.84 1783.00,59.17 1783.00,59.17
             1783.00,59.17 1876.00,69.17 1876.00,69.17
             1876.00,69.17 1920.00,74.00 1920.00,74.00
             1920.00,74.00 1920.00,0.00 1920.00,0.00
             1920.00,0.00 0.00,0.00 0.00,0.00 Z"
                />
            </svg>
        );
    }

}

export interface SectionProps {
    style?: React.CSSProperties
}

export const SectionColor = {
    PRIMARY: "primary",
    SECONDARY: "secondary"
};

export default class Section extends React.Component<SectionProps, Record<string, unknown>> {

    public static sections: {
        number: number;
        last: string;
    } = {
        number: 0,
        last: undefined
    };

    render(): React.ReactNode {
        const { children, style } = this.props;
        const color = Section.sections.number % 2 == 0 ? SectionColor.PRIMARY : SectionColor.SECONDARY;
        Section.sections.last = color;
        Section.sections.number++;
        return (            
            <section className={`section section-${color}`} style={style}>
                {color !== SectionColor.PRIMARY && <SectionDivider reverse={false} />}
                <div className="section-content w-100">
                    <div className="col-12 col-sm-11 col-md-10 col-lg-9 col-xl-8 mx-auto">{children}</div>
                </div>
                {color !== SectionColor.PRIMARY && <SectionDivider reverse={true} />}
            </section>
        );
    }

}
