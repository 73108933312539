import React = require("react");
import CommandI, { FieldI } from "../../types/Command";
import UserGuildI from "../../types/UserGuild";
import EmojiPicker from "../../utils/EmojiPicker";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import uniqid = require("uniqid");
import Field, { UIField, UIFieldProps } from "./Field";
import AnimateHeight from "react-animate-height";

export interface SimpleListTexts {
    noAccess: string | unknown;
}

export interface SimpleListProps extends UIFieldProps {
    command: CommandI;
    guild: UserGuildI;
    field: FieldI;
    emojiPicker: React.RefObject<EmojiPicker>;
    value: Array<unknown>;
}

export interface SimpleListState {
    items: Array<SimpleListItem>;
}

export interface SimpleListItem {
    id: string;
    value: unknown;
    deleted: boolean;
    hidden: boolean;
    field: React.RefObject<Field>;
}

export default class SimpleList extends React.Component<SimpleListProps, SimpleListState> implements UIField {

    constructor(props: SimpleListProps) {
        super(props);
        const { field } = props;
        let { value } = props;
        if (!value) value = [];
        const items: Array<SimpleListItem> = [];
        let i = 0;
        do {
            items.push({
                id: `id-${i}`,
                value: value[i],
                deleted: false,
                hidden: false,
                field: undefined
            });
            i++;
        } while (i < field.min || i < value.length);

        items.push({
            id: `id-${i}`,
            value: value[i],
            deleted: false,
            hidden: true,
            field: undefined
        });

        this.state = {
            items: items
        };
    }

    render(): React.ReactNode {
        const { field, texts } = this.props;
        const { items } = this.state;
        const newField = Object.assign({}, field);
        Object.assign(newField, {
            type: field.listType
        });
        return (
            <DragDropContext onDragEnd={this.dragEnd.bind(this)}>
                <Droppable droppableId="simple-list">
                    {(dropProvided) => (
                        <div {...dropProvided.droppableProps} ref={dropProvided.innerRef} className="simple-list">
                            {items.map((item, index) => {
                                const ref: React.RefObject<Field> = React.createRef();
                                item.field = ref;
                                return (
                                    <Draggable key={item.id} draggableId={item.id} index={index} isDragDisabled={item.hidden}>
                                        {(dragProvided, dragSnapshot) => (
                                            <div ref={dragProvided.innerRef} {...dragProvided.draggableProps} {...dragProvided.dragHandleProps} className={`simple-list-item ${dragSnapshot.isDragging ? "dragged" : ""} ${item.deleted ? "deleted" : ""}`} style={{ ...dragProvided.draggableProps.style }}>
                                                <AnimateHeight duration={500} height={item.hidden ? 0 : "auto"}>
                                                    <div className="simple-list-content-wrapper">
                                                        <i className="fas fa-grip-lines simple-list-move-item"></i>
                                                        <span className="simple-list-item-content">
                                                            <Field texts={texts} ref={ref} onChange={this.updateValue.bind(this)} field={{ ...newField, required: index + 1 <= (field.min || 0) }} guild={this.props.guild} command={this.props.command} emojiPicker={this.props.emojiPicker} value={item.value} disableLabels={true} />
                                                        </span>
                                                        {index + 1 > (field.min || 0) && <i className="fas fa-trash simple-list-delete-item" data-item-id={item.id} onClick={this.deleteItem.bind(this)}></i>}

                                                        {item.deleted && (
                                                            <div className="simple-list-confirm-delete">
                                                                <span data-item-id={item.id} onClick={this.cancelDelete.bind(this)} className="small text-light">
                                                                    <span className="btn btn-success btn-sm">
                                                                        <i className="fas fa-undo"></i>
                                                                    </span>
                                                                    {texts.cancel}
                                                                </span>
                                                                <span data-item-id={item.id} onClick={this.confirmDelete.bind(this)} className="small text-light">
                                                                    <span className="btn btn-danger btn-sm">
                                                                        <i className="fas fa-trash"></i>
                                                                    </span>
                                                                    {texts.delete}
                                                                </span>
                                                            </div>
                                                        )}
                                                    </div>
                                                </AnimateHeight>
                                            </div>
                                        )}
                                    </Draggable>
                                );
                            })}
                            {dropProvided.placeholder}
                            {items.length < field.max && <div onClick={this.addField.bind(this)} className="simple-list-add-item"></div>}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        );
    }

    private addField(): void {
        const items = this.state.items.map((i) => {
            if (i.hidden) i.hidden = false;
            return i;
        });
        this.setState({
            items: [...items, { id: uniqid(), value: undefined, deleted: false, hidden: true, field: undefined }]
        });
    }

    private reorder(list: Array<SimpleListItem>, startIndex: number, endIndex: number): Array<SimpleListItem> {
        if (list[endIndex].hidden) endIndex--;
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    }

    private dragEnd(result: DropResult): void {
        // dropped outside the list
        if (!result.destination) return;

        const items = this.reorder(this.state.items, result.source.index, result.destination.index);
        items[result.destination.index].deleted = false;

        this.setState({
            items: items
        });
    }

    private deleteItem(event: React.MouseEvent): void {
        const id = (event.currentTarget as HTMLElement).dataset.itemId;
        if (id) {
            const items = this.state.items.map((i) => {
                if (i.id === id) i.deleted = true;
                return i;
            });

            this.setState({
                items: items
            });
        }
    }

    private cancelDelete(event: React.MouseEvent): void {
        const id = (event.currentTarget as HTMLElement).dataset.itemId;
        if (id) {
            const items = this.state.items.map((i) => {
                if (i.id === id) i.deleted = false;
                return i;
            });

            this.setState({
                items: items
            });
        }
    }

    private confirmDelete(event: React.MouseEvent): void {
        const id = (event.currentTarget as HTMLElement).dataset.itemId;
        if (id) {
            const items = this.state.items.filter((i) => i.id !== id);

            this.setState(
                {
                    items: items
                },
                () => {
                    if (items.length === 1) this.addField();
                    try {
                        const value = this.getValue(true);
                        this.triggerOnChange(value);
                    } catch (e) {}
                }
            );
        }
    }

    updateValue(): void {
        const items = this.state.items.map((item) => {
            try {
                item.value = item.field.current.getValue(true);
            } catch (e) {}
            return item;
        });
        this.setState(
            {
                items: items
            },
            () => {
                try {
                    const value = this.getValue(true);
                    this.triggerOnChange(value);
                } catch (e) {}
            }
        );
    }

    public triggerOnChange(value: Array<unknown>): void {
        if (this.props.onChange) {
            this.props.onChange({
                name: this.props.field.name,
                value: value
            });
        }
    }

    checkValue(): boolean {
        let error = false;
        this.state.items.forEach((item) => {
            if (item.hidden) return;
            try {
                item.field.current.getValue(true);
            } catch (e) {
                error = true;
            }
        });
        return error;
    }

    public getValue(disableErrorDisplay?: boolean): Array<unknown> {
        const values: Array<unknown> = [];
        let error = false;
        this.state.items.forEach((item, index) => {
            if (item.hidden) return;
            try {
                const value = item.field.current.getValue(disableErrorDisplay);
                if (!value && this.props.field.min && index < this.props.field.min) error = true;
                values.push(value);
            } catch (e) {
                error = true;
            }
        });
        if (error) {
            if (!disableErrorDisplay) this.displayError();
            throw new Error();
        }
        return values.filter(Boolean);
    }

    public displayError(): void {
        //Error
    }

}
