import React = require("react");
import { FooterTexts } from "../partials/Footer";
import { HeaderTexts } from "../partials/Header";
import Section from "../partials/Section";
import TemplateMain from "../templates/TemplateMain";
import UserI from "../types/User";

export interface TutorialTexts {
    page: string | unknown;
    title: string | unknown;
    introduction: {
        title: string | unknown;
        whatIsWebot: {
            title: string | unknown;
        };
    };
    howToUse: {
        title: string | unknown;
        basics: {
            title: string | unknown;
            introduction: string | unknown;
            fromDiscord: string | unknown;
        };
        advanced: {
            title: string | unknown;
        };
    };
    discordTips: {
        title: string | unknown;
        global: {
            title: string | unknown;
            devMode: {
                title: string | unknown;
                userSettings: {
                    title: string | unknown;
                    description: string | unknown;
                };
                appearence: {
                    title: string | unknown;
                };
                checkDevMode: {
                    title: string | unknown;
                    description: string | unknown;
                };
            };
        };
        messages: {
            title: string | unknown;
            copyId: {
                title: string | unknown;
                prerequisites: {
                    title: string | unknown;
                    description: string | unknown;
                };
                menu: {
                    title: string | unknown;
                    description: string | unknown;
                };
                clickCopy: {
                    title: string | unknown;
                    description: string | unknown;
                };
                paste: {
                    title: string | unknown;
                    description: string | unknown;
                };
            };
        };
        roles: {
            title: string | unknown;
            copyId: {
                title: string | unknown;
                prerequisites: {
                    title: string | unknown;
                    description: string | unknown;
                };
                menu: {
                    title: string | unknown;
                    description: string | unknown;
                };
                clickCopy: {
                    title: string | unknown;
                    description: string | unknown;
                };
                paste: {
                    title: string | unknown;
                    description: string | unknown;
                };
            };
        };
        channels: {
            title: string | unknown;
            copyId: {
                title: string | unknown;
                prerequisites: {
                    title: string | unknown;
                    description: string | unknown;
                };
                menu: {
                    title: string | unknown;
                    description: string | unknown;
                };
                clickCopy: {
                    title: string | unknown;
                    description: string | unknown;
                };
                paste: {
                    title: string | unknown;
                    description: string | unknown;
                };
            };
        };
        members: {
            title: string | unknown;
            copyId: {
                title: string | unknown;
                prerequisites: {
                    title: string | unknown;
                    description: string | unknown;
                };
            };
        };
    };
}

export interface TutorialProps {
    dark: boolean;
    texts: {
        header: HeaderTexts;
        footer: FooterTexts;
        tutorial: TutorialTexts;
    };
    user?: UserI;
    page: string;
    language: string;
    path: string;
}

export default class Tutorial extends React.Component<TutorialProps> {

    constructor(props: TutorialProps) {
        super(props);
    }

    render(): React.ReactNode {
        const { texts } = this.props;
        return (
            <TemplateMain {...this.props}>
                <Section style={{ marginTop: "100px" }}>
                    <h1 className="text-center">{texts.tutorial.title}</h1>
                </Section>
                <Section>
                    <ul className="section-table col-7 mx-auto">
                        <li>
                            <a href="#intro">{texts.tutorial.introduction.title}</a>
                            <ul>
                                <li>
                                    <a href="#whatIsXebot">{texts.tutorial.introduction.whatIsWebot.title}</a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <a href="#howToUse">{texts.tutorial.howToUse.title}</a>
                            <ul>
                                <li>
                                    <a href="#basics">{texts.tutorial.howToUse.basics.title}</a>
                                    <ul>
                                        <li>
                                            <a href="#basicsIntro">{texts.tutorial.howToUse.basics.introduction}</a>
                                        </li>
                                        <li>
                                            <a href="#basicsFromDiscord">{texts.tutorial.howToUse.basics.fromDiscord}</a>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <a href="#advanced">{texts.tutorial.howToUse.advanced.title}</a>
                                    <ul></ul>
                                </li>
                                <li>
                                    <a href="#getHelp">Get Help</a>
                                    <ul></ul>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <a href="#discordTips">{texts.tutorial.discordTips.title}</a>
                            <ul>
                                <li>
                                    <a href="#discordTipsGlobal">{texts.tutorial.discordTips.global.title}</a>
                                    <ul>
                                        <li>
                                            <a href="#enableDevMode">{texts.tutorial.discordTips.global.devMode.title}</a>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <a href="#discordTipsMessages">{texts.tutorial.discordTips.messages.title}</a>
                                    <ul>
                                        <li>
                                            <a href="#copyMessagesId">{texts.tutorial.discordTips.messages.copyId.title}</a>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <a href="#discordTipsRoles">{texts.tutorial.discordTips.roles.title}</a>
                                    <ul>
                                        <li>
                                            <a href="#copyRolesId">{texts.tutorial.discordTips.roles.copyId.title}</a>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <a href="#discordTipsChannels">{texts.tutorial.discordTips.channels.title}</a>
                                    <ul>
                                        <li>
                                            <a href="#copyChannelsId">{texts.tutorial.discordTips.channels.copyId.title}</a>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <a href="#discordTipsmembers">{texts.tutorial.discordTips.members.title}</a>
                                    <ul>
                                        <li>
                                            <a href="#copyMembersId">{texts.tutorial.discordTips.members.copyId.title}</a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </Section>
                <Section>
                    <article>
                        <div>
                            <p className="h2" id="intro">
                                <span>{texts.tutorial.introduction.title}</span>
                            </p>
                            <div>
                                <div>
                                    <p className="h4" id="whatIsXebot">
                                        <span>{texts.tutorial.introduction.whatIsWebot.title}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <p className="h2" id="howToUse">
                                <span>{texts.tutorial.howToUse.title}</span>
                            </p>
                            <div>
                                <div>
                                    <p className="h4" id="basics">
                                        <span>{texts.tutorial.howToUse.basics.title}</span>
                                    </p>
                                    <div>
                                        <div>
                                            <p className="h5" id="basicsIntro">
                                                <span>{texts.tutorial.howToUse.basics.introduction}</span>
                                            </p>
                                        </div>
                                        <div>
                                            <p className="h5" id="basicsFromDiscord">
                                                <span>{texts.tutorial.howToUse.basics.fromDiscord}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <p className="h4" id="advanced">
                                        <span>{texts.tutorial.howToUse.advanced.title}</span>
                                    </p>
                                    <div></div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <p className="h2" id="discordTips">
                                <span>{texts.tutorial.discordTips.title}</span>
                            </p>
                            <div>
                                <div>
                                    <p className="h4" id="discordTipsGlobal">
                                        <span>{texts.tutorial.discordTips.global.title}</span>
                                    </p>
                                    <div>
                                        <div>
                                            <p className="h5" id="enableDevMode">
                                                <span>{texts.tutorial.discordTips.global.devMode.title}</span>
                                            </p>
                                            <div>
                                                <ul className="list-number">
                                                    <li>
                                                        <p className="h8">{texts.tutorial.discordTips.global.devMode.userSettings.title}</p>
                                                        <div className="details">
                                                            {texts.tutorial.discordTips.global.devMode.userSettings.description}
                                                            <img className="rounded img-fluid" src="/assets/images/tutorial/tips/discord/settings.png" />
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <p className="h8">{texts.tutorial.discordTips.global.devMode.appearence.title}</p>
                                                        <div className="details">
                                                            <img className="rounded img-fluid" src="/assets/images/tutorial/tips/discord/appearence.png" />
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <p className="h8">{texts.tutorial.discordTips.global.devMode.checkDevMode.title}</p>
                                                        <div className="details">
                                                            {texts.tutorial.discordTips.global.devMode.checkDevMode.description}
                                                            <img className="rounded img-fluid" src="/assets/images/tutorial/tips/discord/dev_mode.png" />
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <p className="h4" id="discordTipsMessages">
                                        <span>{texts.tutorial.discordTips.messages.title}</span>
                                    </p>
                                    <div>
                                        <div>
                                            <p className="h5" id="copyMessagesId">
                                                <span>{texts.tutorial.discordTips.messages.copyId.title}</span>
                                            </p>
                                            <div>
                                                <div className="prerequisite">
                                                    <p className="h8">{texts.tutorial.discordTips.messages.copyId.prerequisites.title}</p>
                                                    <p className="details" dangerouslySetInnerHTML={{ __html: texts.tutorial.discordTips.messages.copyId.prerequisites.description as string }}></p>
                                                </div>
                                                <ul className="list-number">
                                                    <li>
                                                        <p className="h8">{texts.tutorial.discordTips.messages.copyId.menu.title}</p>
                                                        <div className="details">
                                                            {texts.tutorial.discordTips.messages.copyId.menu.description}
                                                            <img className="rounded img-fluid" src="/assets/images/tutorial/tips/messages/menu.png" />
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <p className="h8">{texts.tutorial.discordTips.messages.copyId.clickCopy.title}</p>
                                                        <div className="details">
                                                            <p dangerouslySetInnerHTML={{ __html: texts.tutorial.discordTips.messages.copyId.clickCopy.description as string }}></p>
                                                            <img className="rounded img-fluid" src="/assets/images/tutorial/tips/messages/menu_open.png" />
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <p className="h8">{texts.tutorial.discordTips.messages.copyId.paste.title}</p>
                                                        <div className="details">{texts.tutorial.discordTips.messages.copyId.paste.description}</div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <p className="h4" id="discordTipsRoles">
                                        <span>{texts.tutorial.discordTips.roles.title}</span>
                                    </p>
                                    <div>
                                        <div>
                                            <p className="h5" id="copyRolesId">
                                                <span>Copy ID</span>
                                            </p>
                                            <div>
                                                <div className="prerequisite">
                                                    <p className="h8">Prerequisistes</p>
                                                    <p className="details">
                                                        You need to have the <a href="#enableDevMode">developer mode</a> enabled
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <p className="h4" id="discordTipsChannels">
                                        <span>{texts.tutorial.discordTips.channels.title}</span>
                                    </p>
                                    <div>
                                        <div>
                                            <p className="h5" id="copyChannelsId">
                                                <span>Copy ID</span>
                                            </p>
                                            <div>
                                                <div className="prerequisite">
                                                    <p className="h8">Prerequisistes</p>
                                                    <p className="details">
                                                        You need to have the <a href="#enableDevMode">developer mode</a> enabled
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <p className="h4" id="discordTipsmembers">
                                        <span>{texts.tutorial.discordTips.members.title}</span>
                                    </p>
                                    <div>
                                        <div>
                                            <p className="h5" id="copyMembersId">
                                                <span>Copy ID</span>
                                            </p>
                                            <div>
                                                <div className="prerequisite">
                                                    <p className="h8">Prerequisistes</p>
                                                    <p className="details">
                                                        You need to have the
                                                        <a href="#enableDevMode">developer mode</a>
                                                        enabled
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                </Section>
            </TemplateMain>
        );
    }

}
