import React = require("react");
import { FooterTexts } from "../partials/Footer";
import { HeaderTexts } from "../partials/Header";
import Section from "../partials/Section";
import TemplateMain from "../templates/TemplateMain";
import UserI from "../types/User";

export enum ErrorCode {
    NOT_FOUND,
    NO_RESPONSE,
}

export interface ErrorTexts {
    backToIndex: string | unknown;
    NOT_FOUND: {
        title: string | unknown;
        description: string | unknown;
    };
    NO_RESPONSE: {
        title: string | unknown;
        description: string | unknown;
    };
    UNKNOWN: {
        title: string | unknown;
        description: string | unknown;
    };
}

export interface ErrorProps {
    dark: boolean;
    texts: {
        header: HeaderTexts;
        footer: FooterTexts;
        error: ErrorTexts;
    };
    user?: UserI;
    page: string;
    language: string;
    path: string;
    errorCode: ErrorCode;
}

export default class Error extends React.Component<ErrorProps> {

    render(): React.ReactNode {
        const { texts, errorCode } = this.props;
        const error: { title: string | unknown; description: string | unknown } = { title: texts.error.UNKNOWN.title, description: texts.error.UNKNOWN.description };
        switch (errorCode) {
            case ErrorCode.NOT_FOUND:
                error.title = texts.error.NOT_FOUND.title;
                error.description = texts.error.NOT_FOUND.description;
                break;
            case ErrorCode.NO_RESPONSE:
                error.title = texts.error.NO_RESPONSE.title;
                error.description = texts.error.NO_RESPONSE.description;
                break;
        }

        return (
            <TemplateMain {...this.props}>
                <Section style={{ marginTop: "100px" }}>
                    <h1 className="text-center">{error.title}</h1>
                    <p className="text-center">{error.description}</p>
                </Section>
                <Section>
                    <div className="text-center">
                        <a className="btn btn-primary" href="/">
                            {texts.error.backToIndex}
                        </a>
                    </div>
                </Section>
            </TemplateMain>
        );
    }

}
