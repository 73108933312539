import React = require("react");
import { FooterTexts } from "../partials/Footer";
import { HeaderTexts } from "../partials/Header";
import Section from "../partials/Section";
import TemplateMain from "../templates/TemplateMain";
import UserI from "../types/User";
import UserGuildI from "../types/UserGuild";

export interface ServerListTexts {
    page: string | unknown;
    title: string | unknown;
    description: string | unknown;
    guilds: {
        configurable: {
            title: string | unknown;
            description: string | unknown;
            none: string | unknown;
        };
        others: {
            title: string | unknown;
            description: string | unknown;
            none: string | unknown;
        };
        available: {
            title: string | unknown;
            description: string | unknown;
            none: string | unknown;
        };
    };
}

export interface ServerListProps {
    dark: boolean;
    texts: {
        header: HeaderTexts;
        footer: FooterTexts;
        serverList: ServerListTexts;
    };
    user?: UserI;
    page: string;
    language: string;
    path: string;
    guilds: Array<UserGuildI>;
}

export default class ServerList extends React.Component<ServerListProps> {

    constructor(props: ServerListProps) {
        super(props);
        this.state = {
            apiResponse: ""
        };
    }

    render(): React.ReactNode {
        const { texts, guilds } = this.props;
        const gs = {
            admin: guilds.filter((g) => g.admin && g.added),
            available: guilds.filter((g) => g.admin && !g.added),
            others: guilds.filter((g) => !g.admin && g.added)
        };
        let key = 0;
        return (
            <TemplateMain {...this.props}>
                <Section style={{ marginTop: "100px" }}>
                    <h1 className="text-center">{texts.serverList.title}</h1>
                    <p className="text-center">{texts.serverList.description}</p>
                </Section>
                <Section>
                    <div className="pb-5">
                        <p className="h3">{texts.serverList.guilds.configurable.title}</p>
                        <p>{texts.serverList.guilds.configurable.description}</p>
                        <table className="table table-hover">
                            {gs.admin.length > 0 ? (
                                <tbody>
                                    {gs.admin.map((guild) => (
                                        <tr key={key++}>
                                            <td>
                                                <a className="p-1 unstyled-link d-block" href={`/setup/${guild.id}`}>
                                                    <img className="rounded-circle mr-2" src={guild.iconURL} height="50" width="50" />
                                                    <span className="overflow-hidden h6" style={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                                                        {guild.name}
                                                    </span>
                                                </a>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            ) : (
                                <div className="alert alert-info" role="alert">
                                    {texts.serverList.guilds.configurable.none}
                                </div>
                            )}
                        </table>
                    </div>
                </Section>
                <Section>
                    <div className="pb-5">
                        <p className="h3">{texts.serverList.guilds.others.title}</p>
                        <p>{texts.serverList.guilds.others.description}</p>
                        <table className="table table-hover">
                            {gs.others.length > 0 ? (
                                <tbody>
                                    {gs.others.map((guild) => (
                                        <tr key={key++}>
                                            <td>
                                                <a className="p-1 unstyled-link d-block" href={`/setup/${guild.id}`}>
                                                    <img className="rounded-circle mr-2" src={guild.iconURL} height="50" width="50" />
                                                    <span className="overflow-hidden h6" style={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                                                        {guild.name}
                                                    </span>
                                                </a>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            ) : (
                                <div className="alert alert-info" role="alert">
                                    {texts.serverList.guilds.others.none}
                                </div>
                            )}
                        </table>
                    </div>
                </Section>
                <Section>
                    <div className="pb-5">
                        <p className="h3">{texts.serverList.guilds.available.title}</p>
                        <p>{texts.serverList.guilds.available.description}</p>
                        <table className="table table-hover">
                            {gs.available.length > 0 ? (
                                <tbody>
                                    {gs.available.map((guild) => (
                                        <tr key={key++}>
                                            <td>
                                                <a className="p-1 unstyled-link d-block" href={`https://discord.com/oauth2/authorize?client_id=354901722432405506&scope=bot&permissions=8&guild_id=${guild.id}`}>
                                                    <img className="rounded-circle mr-2" src={guild.iconURL} height="50" width="50" />
                                                    <span className="overflow-hidden h6" style={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                                                        {guild.name}
                                                    </span>
                                                </a>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            ) : (
                                <div className="alert alert-info" role="alert">
                                    {texts.serverList.guilds.available.none}
                                </div>
                            )}
                        </table>
                    </div>
                </Section>
            </TemplateMain>
        );
    }

}
