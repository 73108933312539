import React = require("react");
import uniqid = require("uniqid");
import ChannelI from "../../types/Channel";
import CommandI, { FieldI } from "../../types/Command";
import RoleI from "../../types/Role";
import UserGuildI from "../../types/UserGuild";
import EmojiPicker from "../../utils/EmojiPicker";
import ChannelField from "./Channel";
import CheckBoxField from "./CheckBox";
import ColorField from "./Color";
import EmojiField from "./Emoji";
import ImageField from "./Image";
import LinkField from "./Link";
import List from "./List";
import NumberField from "./Number";
import RoleField from "./Role";
import SimpleList from "./SimpleList";
import SimpleTextField from "./SimpleText";
import TextField from "./Text";

export interface UIField {
    getValue: (disableErrorDisplay?: boolean) => unknown;
    displayError: () => void;
    checkValue: (value?: unknown) => boolean;
    triggerOnChange: (value?: unknown) => void;
}
export interface UIFieldProps {
    onChange?: (value: { [key: string]: unknown; name: string; value: unknown }) => void;
    texts: FieldTexts;
}

export interface FieldTexts {
    error: {
        requiredValue: string | unknown;
        requiredEmoji: string | unknown;
        invalidImage: string | unknown;
        invalidLink: string | unknown;
        invalidNumber: string | unknown;
        invalidText: string | unknown;
        textTooLong: string | unknown;
    };

    returnToOverview: string | unknown;
    noData: string | unknown;
    cancel: string | unknown;
    edit: string | unknown;
    delete: string | unknown;
}

export interface FieldProps extends UIFieldProps {
    id?: string;
    texts: FieldTexts;
    command: CommandI;
    guild: UserGuildI;
    field: FieldI;
    emojiPicker: React.RefObject<EmojiPicker>;
    value: unknown;
    disableLabels?: boolean;
    className?: string;
}

export interface FieldState {
    id: string;
}

export default class Field extends React.Component<FieldProps, FieldState> {

    constructor(props: FieldProps) {
        super(props);
        this.state = {
            id: props.id || uniqid()
        };
    }

    private fieldRef: React.RefObject<never> = React.createRef();

    private getComponent(): React.ReactNode {
        const { field, value } = this.props;
        switch (field.type) {
            case "simple-list":
                return <SimpleList ref={this.fieldRef} {...this.props} onChange={this.onChange.bind(this)} value={value as Array<unknown>} />;
            case "list":
                return <List ref={this.fieldRef} {...this.props} onChange={this.onChange.bind(this)} value={value as Array<Record<string | number, unknown>>} />;
            case "role":
                return <RoleField ref={this.fieldRef} {...this.props} onChange={this.onChange.bind(this)} value={value as RoleI} />;
            case "channel":
                return <ChannelField ref={this.fieldRef} {...this.props} onChange={this.onChange.bind(this)} value={value as ChannelI} />;
            case "color":
                return <ColorField ref={this.fieldRef} {...this.props} onChange={this.onChange.bind(this)} value={value as string} />;
            case "image":
                return <ImageField ref={this.fieldRef} {...this.props} onChange={this.onChange.bind(this)} value={value as string} />;
            case "link":
                return <LinkField ref={this.fieldRef} {...this.props} onChange={this.onChange.bind(this)} value={value as string} />;
            case "number":
                return <NumberField ref={this.fieldRef} {...this.props} onChange={this.onChange.bind(this)} value={value as number} />;
            case "text":
                return <TextField ref={this.fieldRef} {...this.props} onChange={this.onChange.bind(this)} emojiPicker={this.props.emojiPicker} value={value as { raw: unknown; converted: string }} />;
            case "simple-text":
                return <SimpleTextField ref={this.fieldRef} {...this.props} onChange={this.onChange.bind(this)} value={value as string} />;
            case "check":
                return <CheckBoxField ref={this.fieldRef} {...this.props} onChange={this.onChange.bind(this)} value={value as boolean} />;
            case "emoji":
                return <EmojiField ref={this.fieldRef} {...this.props} onChange={this.onChange.bind(this)} emojiPicker={this.props.emojiPicker} value={value as string} />;
            case "id":
                return <SimpleTextField ref={this.fieldRef} {...this.props} onChange={this.onChange.bind(this)} value={value as string} match={/^[0-9]{18}$/} />;
            default:
                return <div>Unknown field : {field.type}</div>;
        }
    }

    render(): React.ReactNode {
        const { field, disableLabels } = this.props;
        if (disableLabels) {
            return this.getComponent();
        } else {
            return (
                <div className={`field ${field.required ? "field-required" : ""} ${this.props.className}`}>
                    {field.new && <span className="field-new">NEW</span>}
                    <div className="field-content">
                        {field.description && <label className="field-title">{field.description}</label>}
                        {this.getComponent()}
                        {field.hint && <small className="field-hint" dangerouslySetInnerHTML={{ __html: field.hint as string }}></small>}
                    </div>
                </div>
            );
        }
    }

    public getValue(disableErrorDisplay?: boolean): unknown {
        if (!this.fieldRef.current) return undefined;
        return (this.fieldRef.current as UIField).getValue(disableErrorDisplay);
    }

    public getId(): string {
        return this.state.id;
    }

    onChange(value: { [key: string]: unknown; name: string; value: unknown }): void {
        if (this.props.onChange) {
            this.props.onChange({
                ...value,
                id: this.state.id
            });
        }
    }

}
