import React = require("react");
import AnimateHeight from "react-animate-height";
import CommandI, { FieldI } from "../../types/Command";
import UserGuildI from "../../types/UserGuild";
import EmojiPicker from "../../utils/EmojiPicker";
import Twemoji from "../../utils/Twemoji";
import { UIField, UIFieldProps } from "./Field";

export interface EmojiFieldProps extends UIFieldProps {
    command: CommandI;
    guild: UserGuildI;
    field: FieldI;
    value?: string;
    emojiPicker: React.RefObject<EmojiPicker>;
}

export interface EmojiFieldState {
    value: string;
    alt: string;
    invalid: boolean;
}

export default class EmojiField extends React.Component<EmojiFieldProps, EmojiFieldState> implements UIField {

    constructor(props: EmojiFieldProps) {
        super(props);
        let value = props.value;
        let alt = props.value;
        if (value && value.length > 10) {
            const emoji = props.guild.emojis.find((e) => e.identifier === value);
            if (emoji) {
                value = emoji.url;
                alt = emoji.identifier;
            } else {
                value = undefined;
                alt = undefined;
            }
        }

        this.state = {
            value: value || (props.field.default as string),
            alt: alt || (props.field.default as string),
            invalid: false
        };
    }

    render(): React.ReactNode {
        const { texts } = this.props;
        return (
            <div>
                <span onClick={this.selectEmoji.bind(this)} className={`custom-field-emoji ${this.state.invalid ? "field-invalid" : ""}`}>
                    {!this.state.value && <Twemoji emoji="🙁" className="custom-field-emoji-content empty" />}
                    {this.state.value && this.state.value.length < 8 && <Twemoji emoji={this.state.value} className="custom-field-emoji-content" />}
                    {this.state.value && this.state.value.length >= 8 && (
                        <span className="custom-field-emoji-content">
                            <img src={this.state.value} />
                        </span>
                    )}
                </span>
                <AnimateHeight height={this.state.invalid ? "auto" : 0}>
                    <small className="field-error">{texts.error.requiredEmoji}</small>
                </AnimateHeight>
            </div>
        );
    }

    async selectEmoji(): Promise<void> {
        this.props.emojiPicker.current.show();
        try {
            const emoji = await this.props.emojiPicker.current.waitSelection();
            this.setState({
                value: emoji.src,
                alt: emoji.alt
            });
            if (this.checkValue(emoji.alt)) this.triggerOnChange(emoji.alt);
        } catch (e) {
            console.log(e);
        }
        this.props.emojiPicker.current.hide();
    }

    triggerOnChange(value: string): void {
        if (this.props.onChange) {
            this.props.onChange({
                name: this.props.field.name,
                value: value
            });
        }
    }

    checkValue(value?: string): boolean {
        if (!value) value = this.state.alt;
        const result = !(this.props.field.required && !value);
        if (result) {
            this.setState({
                invalid: false
            });
        }
        return result;
    }

    public getValue(): string {
        if (!this.checkValue()) {
            this.displayError();
            throw new Error();
        }
        return this.state.alt;
    }

    displayError(): void {
        this.setState({
            invalid: true
        });
    }

}
