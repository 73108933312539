import React = require("react");
import ReactDOM = require("react-dom");
import { ToastProvider } from "react-toast-notifications";
import { ModalProvider } from "../client/utils/Modal";
import { routes, Route } from "./routes";

const params = ((window as unknown) as Record<string, unknown>).__APP_INITIAL_STATE__ as Record<string, unknown>;

console.log("Route :", params.path);
console.log("Params : ", params);
let RouteComponent: typeof React.Component;
let routeModule: Route;

const path: Array<string> = (params.path as string).split("/").filter(Boolean);
function isAValidRoute(routeString: string): boolean {
    const route: Array<string> = routeString.split("/").filter(Boolean);
    let valid = route.length === path.length;
    route.forEach((way, i) => {
        if (!valid || !path[i]) {
            valid = false;
            return;
        }
        if (route[i] === "*") return;
        valid = path[i] === way;
    });
    return valid;
}

for (const r of routes) if (isAValidRoute(r.path)) routeModule = r;

console.log("Selected Route :", routeModule.path);

if (routeModule) {
    routeModule.require().then((module) => {
        RouteComponent = module.default as typeof React.Component;

        const App = () => (
            <ToastProvider>
                <ModalProvider>
                <RouteComponent {...params} />
                </ModalProvider>
            </ToastProvider>
        );

        ReactDOM.hydrate(<App />, document);
    });
}
