import React = require("react");
import UserI from "../types/User";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export interface HeaderTexts {
    features: string | unknown;
    tutorial: string | unknown;
    myServers: string | unknown;
    logout: string | unknown;
    login: string | unknown;
    options: string | unknown;
    banner: {
        bugs: string | unknown;
    };
}

export interface HeaderProps {
    texts: HeaderTexts;
    user: UserI;
    language: string;
    theme: string;
}

export interface HeaderState {
    theme: string;
}

export default class Header extends React.Component<HeaderProps, HeaderState> {

    constructor(props: HeaderProps) {
        super(props);
        this.state = {
            theme: props.theme || "dark"
        };
    }

    changeLanguage(event: React.MouseEvent): void {
        event.preventDefault();
        const language: string = event.currentTarget.getAttribute("data-language");
        cookies.set("language", language, { path: "/" });
        window.location.reload();
    }

    changeTheme(event: React.MouseEvent): void {
        const theme: string = event.currentTarget.getAttribute("data-theme");
        document.body.setAttribute("data-theme", theme);
        cookies.set("theme", theme, { path: "/" });
        this.setState({ theme: theme });
    }

    render(): React.ReactNode {
        const { texts, user, language } = this.props;

        return (
            <header className="mb-5 sticky-top mx-2">
                {/* <div className="header-alert mt-2" dangerouslySetInnerHTML={{ __html: texts.banner.bugs as string }}></div> */}
                <nav className="navbar navbar-expand-md navbar-light bg-light mb-2">
                    <a className="navbar-brand mb-0 h1" href="/">
                        Xebot
                    </a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarContent" aria-controls="navbarContent" aria-expanded="false" aria-label="Afficher le menu">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarContent">
                        <ul className="navbar-nav mr-auto">
                            <li className="nav-item">
                                <a className="nav-link" href="/features">
                                    {texts.features}
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/tutorial">
                                    {texts.tutorial}
                                </a>
                            </li>
                        </ul>

                        {/* <!-- Séprateur lorsque le menu est en dropdown --> */}
                        <hr className="d-md-none" />

                        <ul className="navbar-nav">
                            {/* <!-- Medium+ --> */}
                            {user ? (
                                <li className="nav-item dropdown border-right d-md-block d-none">
                                    <span className="nav-link dropdown-toggle" id="navbarUserMenuMd" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        {user.avatar != null && <img className="rounded-circle mr-2" height="28" src={user.avatarURL} />}
                                        {user.username}
                                    </span>
                                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarUserMenuMd">
                                        <a className="dropdown-item" rel="nofollow" href="/setup">
                                            {texts.myServers}
                                        </a>
                                        <div className="dropdown-divider"></div>
                                        <a className="dropdown-item text-danger" rel="nofollow" href="/disconnect">
                                            {texts.logout}
                                        </a>
                                    </div>
                                </li>
                            ) : (
                                <li className="nav-item border-right d-md-block d-none">
                                    <a className="nav-link" rel="nofollow" href="/login">
                                        {texts.login}
                                    </a>
                                </li>
                            )}
                            {/* <!-- -Medium --> */}
                            {user ? (
                                <li className="nav-item dropdown d-md-none">
                                    <span className="nav-link dropdown-toggle" id="navbarUserMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        {user.avatar && <img className="rounded-circle mr-2" height="28" src={user.avatarURL} />}
                                        {user.username}
                                    </span>
                                    <div className="dropdown-menu" aria-labelledby="navbarUserMenu">
                                        <a className="dropdown-item" rel="nofollow" href="/setup">
                                            {texts.myServers}
                                        </a>
                                        <div className="dropdown-divider"></div>
                                        <a className="dropdown-item text-danger" rel="nofollow" href="/disconnect">
                                            {texts.logout}
                                        </a>
                                    </div>
                                </li>
                            ) : (
                                <li className="nav-item d-md-none">
                                    <a className="nav-link" rel="nofollow" href="/login">
                                        {texts.login}
                                    </a>
                                </li>
                            )}

                            <li className="nav-item dropdown">
                                <span className="nav-link dropdown-toggle" id="navbarOptionsMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {texts.options}
                                </span>
                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarOptionsMenu">
                                    <div className="dropdown-item text-center align-middle">
                                        <div className={`option-icon ${this.state.theme === "light" ? "option-icon-active" : ""}`} data-theme="light" onClick={this.changeTheme.bind(this)} style={{ backgroundColor: "#fff" }}></div>
                                        <div className={`option-icon ${this.state.theme === "dark" ? "option-icon-active" : ""}`} data-theme="dark" onClick={this.changeTheme.bind(this)} style={{ backgroundColor: "##171717" }}></div>
                                    </div>
                                    <div className="dropdown-divider"></div>
                                    <div className="dropdown-item text-center align-middle">
                                        <img id="frLanguage" className={`option-icon ${language === "fr" ? "option-icon-active" : ""}`} src="/assets/images/icons/fr.png" data-language="fr" onClick={this.changeLanguage} />
                                        <img id="enLanguage" className={`option-icon ${language === "en" ? "option-icon-active" : ""}`} src="/assets/images/icons/en.png" data-language="en" onClick={this.changeLanguage} />
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </nav>
            </header>
        );
    }

}
