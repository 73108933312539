import React = require("react");
import AnimateHeight from "react-animate-height";
import CommandI, { FieldI } from "../../types/Command";
import UserGuildI from "../../types/UserGuild";
import { UIFieldProps } from "./Field";

export interface SimpleTextFieldProps extends UIFieldProps {
    command: CommandI;
    guild: UserGuildI;
    field: FieldI;
    value?: string;
    match?: RegExp;
}

export interface SimpleTextFieldState {
    invalid: boolean;
    timeout: NodeJS.Timeout;
}

export default class SimpleTextField extends React.Component<SimpleTextFieldProps, SimpleTextFieldState> {

    constructor(props: SimpleTextFieldProps) {
        super(props);
        this.state = {
            invalid: false,
            timeout: undefined
        };
    }
    private input: React.RefObject<HTMLInputElement> = React.createRef();

    render(): React.ReactNode {
        const { field, value, texts } = this.props;
        return (
            <div>
                <input onChange={this.updateValue.bind(this)} ref={this.input} type="text" required={field.required} defaultValue={value || (field.default as string)} name={field.name} className={`form-control ${this.state.invalid ? "field-invalid" : ""}`} />
                <AnimateHeight height={this.state.invalid ? "auto" : 0}>
                    <small className="field-error">{texts.error.invalidText}</small>
                </AnimateHeight>
            </div>
        );
    }

    updateValue(): void {
        if (this.checkValue()) {
            if (this.state.timeout) clearTimeout(this.state.timeout);
            const t = setTimeout(
                (() => {
                    this.triggerOnChange(this.input.current.value);
                }).bind(this),
                3000
            );
            this.setState({
                timeout: t
            });
        }
    }

    triggerOnChange(value: string): void {
        if (this.props.onChange) {
            this.props.onChange({
                name: this.props.field.name,
                value: value
            });
        }
    }

    checkValue(): boolean {
        const result = !((this.props.field.required && (!this.input.current.value || this.input.current.value.length == 0)) || (this.props.match && this.input.current.value && !this.input.current.value.match(this.props.match)));
        if (result) {
            this.setState({
                invalid: false
            });
        }
        return result;
    }

    public getValue(): string {
        if (!this.checkValue()) {
            this.displayError();
            throw new Error();
        }
        return this.input.current.value;
    }

    public displayError(): void {
        this.setState({
            invalid: true
        });
    }

}
