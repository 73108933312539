import React = require("react");
import { FooterTexts } from "../partials/Footer";
import { HeaderTexts } from "../partials/Header";
import { SetupMenuTexts } from "../partials/SetupMenu";
import TemplateSetup from "../templates/TemplateSetup";
import UserI from "../types/User";
import UserGuildI from "../types/UserGuild";

export interface DashBoardTexts {
    page: string | unknown;
    anyIdea: string | unknown;
}

export interface DashBoardProps {
    dark: boolean;
    texts: {
        header: HeaderTexts;
        footer: FooterTexts;
        dashboard: DashBoardTexts;
        setupMenu: SetupMenuTexts;
    };
    user?: UserI;
    page: string;
    language: string;
    path: string;
    guild: UserGuildI;
}

export default class DashBoard extends React.Component<DashBoardProps> {

    render(): React.ReactNode {
        const { texts } = this.props;
        return (
            <TemplateSetup {...this.props}>
                <div className="text-center">
                    <h3 className="text-center">{texts.dashboard.anyIdea}</h3>
                    <a href="https://discord.gg/AHEsw8rguJ" target="_blank" className="btn btn-primary btn-glow-animate mt-3">
                        {texts.footer.join.text}
                    </a>
                </div>
            </TemplateSetup>
        );
    }

}
